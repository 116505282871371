import gql from 'graphql-tag'
import { USER_PROFILE_FRAGMENT } from './queries'

const IDENTIFICATION_SHARED_FRAGMENT = `identification {
  adminStatus
  clientStatus
  companyDetailsACN
  companyDetailsIsListed
  companyDetailsIsRegulated
  companyDetailsIsSubsidiaryListed
  companyDetailsIsNormalType
  companyDetailsHasBeneficialOwners
  companyDetailsListedExchangeName
  companyDetailsName
  companyDetailsNatureOfBusiness
  companyDetailsPrincipalPlaceOfBusiness
  companyDetailsRegisteredAddress
  companyDetailsRegulatorLicenseNumber
  companyDetailsRegulatorName
  companyDetailsSubsidiaryListedCompanyName
  companyDetailsSubsidiaryListedExchangeName
  companyDetailsTaxFatcaStatus
  companyDetailsTaxFatcaStatusOther
  companyDetailsOnlyOperatesInAustralia
  companyDetailsTaxForeignResidentCountry
  companyDetailsTaxGIIN
  companyDetailsTaxHasBeneficialOwnerForeignResident
  companyDetailsTaxHasGIIN
  companyDetailsTaxIsForeignResident
  companyDetailsTaxType
  dataType
  entityType
  hasMacAccount
  id
  macAccountName
  macAccountNumber
  postalAddress
  taxExemptionDetails
  taxExemptionType
  taxStatus
  userId
  organisationId
  step
  individuals {
    emailAddress
    firstName
    greenIdToken
    greenIdVerificationId
    greenIdVerificationStatus
    id
    identificationId
    isAccountSignatory
    isForeignResident
    isApprover
    isBeneficialOwner
    isDirectorOrSecretary
    isMacVerified
    isValidated
    macVerifiedNumber
    phoneMobile
    surname
    title
    userRole
    simpleKycDataIndividualId
  }
}
`

export const CREATE_DOCUMENT_MUTATION = gql`
  mutation CreateDocument($input: CreateDocumentInput!) {
    createDocument(input: $input) {
      document {
        id
        status
      }
    }
  }
`
export const CANCEL_DRAFT_MUTATION = gql`
  mutation CancelDraft($input: CancelDraftInput!) {
    cancelDraft(input: $input) {
      draft {
        id
        status
      }
    }
  }
`
export const CREATE_ORGANISATION_MUTATION = gql`
  mutation CreateOrganisation($input: CreateOrganisationInput!) {
    createOrganisation(input: $input) {
      id
      organisation {
        referralCode
      }
    }
  }
`

export const UPDATE_ORGANISATION_MUTATION = gql`
  mutation UpdateOrganisation($input: UpdateOrganisationInput!) {
    updateOrganisation(input: $input) {
      clientMutationId
      id
      abn
      name
      address
    }
  }
`

export const UPDATE_IDENTIFICATION_INDIVIDUAL_GREEN_ID = gql`
  mutation updateGreenId($input: UpdateGreenIdInput) {
    updateGreenId(input: $input) {
      clientMutationId
      identificationIndividual {
        greenIdVerificationStatus
      }
    }
  }
`

export const CREATE_DOCUMENT_COMMENT_MUTATION = gql`
  mutation CreateDocumentComment($input: CreateCommentInput!) {
    createComment(input: $input) {
      clientMutationId
      comment {
        id
        author {
          familyName
          givenName
        }
        content
        createdAt
      }
    }
  }
`

export const CREATE_DRAFT_COMMENT_MUTATION = gql`
  mutation CreateDraftComment($input: CreateDraftCommentInput!) {
    createDraftComment(input: $input) {
      clientMutationId
    }
  }
`

export const INVITE_MEMBER_MUTATION = gql`
  mutation InviteOrganisationMember($input: SendInvitationInput!) {
    sendInvitation(input: $input) {
      clientMutationId
    }
  }
`

export const CREATE_COLLABORATOR_MUTATION = gql`
  mutation CreateDocumentCollaborator($input: CreateDocumentCollaboratorInput!) {
    createDocumentCollaborator(input: $input) {
      clientMutationId
      documentCollaborator {
        id
        emailAddress
        capabilities
      }
    }
  }
`

export const SET_NOTIFICATION_LIST_MUTATION = gql`
  mutation SetDraftNotificationListForCollaborator($input: SetDraftNotificationListForCollaboratorInput!) {
    setDraftNotificationListForCollaborator(input: $input) {
      clientMutationId
    }
  }
`

export const CREATE_DRAFT_COLLABORATOR_MUTATION = gql`
  mutation CreateDraftCollaborator($input: CreateDraftCollaboratorInput!) {
    createDraftCollaborator(input: $input) {
      clientMutationId
    }
  }
`

export const UPDATE_DRAFT_COLLABORATOR_MUTATION = gql`
  mutation UpdateDraftCollaborator($input: UpdateDraftCollaboratorInput!) {
    updateDraftCollaborator(input: $input) {
      clientMutationId
    }
  }
`

export const CREATE_DRAFT_ATTACHMENT_MUTATION = gql`
  mutation CreateDraftAttachment($input: CreateDraftAttachmentInput!) {
    createDraftAttachment(input: $input) {
      clientMutationId
      draftAttachment {
        id
        contentType
        fileName
        received
        uploadPresignedUrl
        variant
      }
    }
  }
`

export const REMOVE_COLLABORATOR_MUTATION = gql`
  mutation RemoveDocumentCollaborator($input: RemoveDocumentCollaboratorInput!) {
    removeDocumentCollaborator(input: $input) {
      clientMutationId
    }
  }
`

export const REMOVE_MEMBER_MUTATION = gql`
  mutation RemoveOrganisationMember($input: RemoveMemberInput!) {
    removeMember(input: $input) {
      clientMutationId
    }
  }
`

export const REMOVE_INVITATION_MUTATION = gql`
  mutation RemoveOrganisationInvitation($input: RemoveInvitationInput!) {
    removeInvitation(input: $input) {
      clientMutationId
    }
  }
`

export const CREATE_IMPORT_JOB_MUTATION = gql`
  mutation CreateDocumentImportJob($input: CreateImportJobInput!) {
    createImportJob(input: $input) {
      importJob {
        id
        files {
          contentMd5Checksum
          contentType
          uploadPresignedUrl
          fileName
        }
        status
      }
    }
  }
`

export const CREATE_DOCUMENT_ATTACHMENT = gql`
  mutation CreateDocumentAttachment($input: CreateDocumentAttachmentInput!) {
    createDocumentAttachment(input: $input) {
      clientMutationId
      documentAttachment {
        id
        contentType
        fileName
        received
        uploadPresignedUrl
      }
    }
  }
`

export const REMOVE_ATTACHMENT_MUTATION = gql`
  mutation RemoveDocumentAttachment($input: RemoveDocumentAttachmentInput!) {
    removeDocumentAttachment(input: $input) {
      clientMutationId
    }
  }
`

export const CREATE_DRAFT_ATTACHMENT = gql`
  mutation CreateDraftAttachment($input: CreateDraftAttachmentInput!) {
    createDraftAttachment(input: $input) {
      clientMutationId
      draftAttachment {
        id
        contentType
        fileName
        received
        uploadPresignedUrl
        draftRequestedDocumentId
      }
    }
  }
`

export const REMOVE_DRAFT_ATTACHMENT = gql`
  mutation RemoveDraftAttachment($input: RemoveDraftAttachmentInput!) {
    removeDraftAttachment(input: $input) {
      clientMutationId
    }
  }
`

export const UPDATE_PROFILE_MUTATION = gql`
  mutation UpdateProfile($input: UpdateProfileInput!) {
    updateProfile(input: $input) {
      clientMutationId
      profile {
        ${USER_PROFILE_FRAGMENT}
      }
    }
  }
`

export const UPDATE_NOTIFIED_AT_MUTATION = gql`
  mutation UpdateNotifiedAt($input: UpdateNotifiedAtInput!) {
    updateNotifiedAt(input: $input) {
      clientMutationId
      profile {
        ${USER_PROFILE_FRAGMENT}
      }
    }
  }
`

export const PROFILE_TERMS_ACCEPTED_MUTATION = gql`
  mutation ProfileTermsAccepted($input: ProfileTermsAcceptedInput!) {
    profileTermsAccepted(input: $input) {
      clientMutationId
    }
  }
`

export const CHANGE_MEMBER_ROLE_MUTATION = gql`
  mutation ChangeOrganisationMemberRole($input: ChangeMemberRoleInput!) {
    changeMemberRole(input: $input) {
      clientMutationId
      member {
        createdAt
        role
        user {
          id
          givenName
          familyName
        }
      }
    }
  }
`

export const ACCEPT_INVITATION_MUTATION = gql`
  mutation AcceptOrganisationInvitation($input: AcceptInvitationInput!) {
    acceptInvitation(input: $input) {
      clientMutationId
    }
  }
`

export const CREATE_DOCUMENT_METADATA = gql`
  mutation CreateDocumentMetadata($input: CreateDocumentMetadataInput!) {
    createDocumentMetadata(input: $input) {
      metadata {
        id
        key
        value
      }
    }
  }
`

export const UPDATE_DOCUMENT_METADATA = gql`
  mutation UpdateDocumentMetadata($input: UpdateDocumentMetadataInput!) {
    updateDocumentMetadata(input: $input) {
      metadata {
        id
        key
        value
      }
    }
  }
`

export const REMOVE_DOCUMENT_METADATA = gql`
  mutation RemoveDocumentMetadata($input: RemoveDocumentMetadataInput) {
    removeDocumentMetadata(input: $input) {
      clientMutationId
    }
  }
`

export const UPDATE_DOCUMENT_MUTATION = gql`
  mutation UpdateDocument($input: UpdateDocumentInput!) {
    updateDocument(input: $input) {
      clientMutationId
    }
  }
`

export const CREATE_ALERT_MUTATION = gql`
  mutation CreateOrganisationAlertConfiguration($input: CreateOrganisationAlertConfigurationInput!) {
    createOrganisationAlertConfiguration(input: $input) {
      clientMutationId
    }
  }
`

export const UPDATE_ALERT_MUTATION = gql`
  mutation UpdateOrganisationAlertConfiguration($input: UpdateOrganisationAlertConfigurationInput!) {
    updateOrganisationAlertConfiguration(input: $input) {
      clientMutationId
      alertConfiguration {
        id
        triggerLeadTime
        triggerProperty
        emailAddresses
        facilityLimit
        facilityLimitIncludeProjectedDocs
        facilityId
      }
    }
  }
`

export const REMOVE_ALERT_MUTATION = gql`
  mutation RemoveOrganisationAlertConfiguration($input: RemoveOrganisationAlertConfigurationInput!) {
    removeOrganisationAlertConfiguration(input: $input) {
      clientMutationId
    }
  }
`

export const CREATE_DRAFT_MUTATION = gql`
  mutation CreateDraft($input: CreateDraftInput!) {
    createDraft(input: $input) {
      clientMutationId
      draft {
        id
      }
    }
  }
`

export const UPDATE_DRAFT_PREVIEW_MUTATION = gql`
  mutation UpdateDraftPreview($input: UpdateDraftInput!) {
    updateDraftPreview(input: $input) {
      clientMutationId
      history {
        id
        recordName
        changeType
        changes {
          label
          field
          newDisplayValue
          newValue
          oldDisplayValue
          oldValue
        }
      }
    }
  }
`

export const UPDATE_DRAFT_MUTATION = gql`
  mutation UpdateDraft($input: UpdateDraftInput!) {
    updateDraft(input: $input) {
      clientMutationId
      draft {
        attachments {
          id
          uploadPresignedUrl
          contentType
          fileName
          received
          contentMd5Checksum
          variant
        }
      }
    }
  }
`

export const REMOVE_DRAFT_COLLABORATOR_MUTATION = gql`
  mutation RemoveDraftCollaborator($input: RemoveDraftCollaboratorInput!) {
    removeDraftCollaborator(input: $input) {
      clientMutationId
    }
  }
`

export const SHARE_DRAFT_TO_ORG_MUTATION = gql`
  mutation ShareDraftToOrganisationFromCollaborator($input: ShareDraftToOrganisationFromCollaboratorInput!) {
    shareDraftToOrganisationFromCollaborator(input: $input) {
      clientMutationId
    }
  }
`

export const SET_DRAFT_APPROVAL_STATUS_FOR_COLLABORATOR_MUTATION = gql`
  mutation SetDraftApprovalStatusForCollaborator($input: SetDraftApprovalStatusForCollaboratorInput!) {
    setDraftApprovalStatusForCollaborator(input: $input) {
      clientMutationId
    }
  }
`

export const SET_DRAFT_REALTIME_AUTHOR_STATE_MUTATION = gql`
  mutation SetDraftRealtimeAuthorState($input: SetDraftRealtimeAuthorStateInput!) {
    setDraftRealtimeAuthorState(input: $input) {
      clientMutationId
      authors {
        author {
          familyName
          givenName
        }
        status
        isCurrentUser
      }
    }
  }
`

export const BEGIN_DRAFT_SUBMISSION_MUTATION = gql`
  mutation BeginDraftSubmission($input: BeginDraftSubmissionInput!) {
    beginDraftSubmission(input: $input) {
      clientMutationId
    }
  }
`

export const CANCEL_DRAFT_SUBMISSION_MUTATION = gql`
  mutation CancelDraftSubmission($input: CancelDraftSubmissionInput!) {
    cancelDraftSubmission(input: $input) {
      clientMutationId
    }
  }
`

export const COMPLETE_DRAFT_SUBMISSION_MUTATION = gql`
  mutation CompleteDraftSubmission($input: CompleteDraftSubmissionInput!) {
    completeDraftSubmission(input: $input) {
      clientMutationId
      applicableRequirements {
        field
        message
        requirementMet
      }
    }
  }
`

export const GENERATE_NEW_STRIPE_SUBSCRIPTION_MUTATION = gql`
  mutation GenerateNewStripeSubscription($input: GenerateNewStripeSubscriptionInput!) {
    generateNewStripeSubscription(input: $input) {
      clientMutationId
    }
  }
`

export const COMPLETE_SURETY_BOND_DRAFT_SUBMISSION_MUTATION = gql`
  mutation CompleteSuretyBondDraftSubmission($input: CompleteSuretyBondDraftSubmissionInput!) {
    completeSuretyBondDraftSubmission(input: $input) {
      clientMutationId
    }
  }
`

export const CREATE_DOCUMENT_REQUEST_FOR_RETURN_MUTATION = gql`
  mutation CreateDocumentRequestForReturn($input: CreateDocumentRequestForReturnInput!) {
    createDocumentRequestForReturn(input: $input) {
      clientMutationId
    }
  }
`

export const REMOVE_DOCUMENT_REQUEST_FOR_RETURN_MUTATION = gql`
  mutation RemoveDocumentRequestForReturn($input: RemoveDocumentRequestForReturnInput!) {
    removeDocumentRequestForReturn(input: $input) {
      clientMutationId
    }
  }
`

export const CREATE_DOCUMENT_HANDOVER_MUTATION = gql`
  mutation CreateDocumentHandover($input: CreateDocumentHandoverInput!) {
    createDocumentHandover(input: $input) {
      clientMutationId
    }
  }
`

export const REMOVE_DOCUMENT_HANDOVER_MUTATION = gql`
  mutation RemoveDocumentHandover($input: RemoveDocumentHandoverInput!) {
    removeDocumentHandover(input: $input) {
      clientMutationId
    }
  }
`

// Used as normal query without gql interpreter
export const CONFIRM_DOCUMENT_HANDOVER_MUTATION = `
  mutation ConfirmDocumentHandover($input: ConfirmDocumentHandoverInput!) {
    confirmDocumentHandover(input: $input) {
      clientMutationId
    }
  }
`

export const CREATE_ORGANISATION_DOCUMENT_METADATA_MUTATION = gql`
  mutation CreateOrganisationDocumentMetadata($input: CreateOrganisationDocumentMetadataInput!) {
    createOrganisationDocumentMetadata(input: $input) {
      clientMutationId
    }
  }
`

export const REMOVE_ORGANISATION_DOCUMENT_METADATA_MUTATION = gql`
  mutation RemoveOrganisationDocumentMetadata($input: RemoveOrganisationDocumentMetadataInput!) {
    removeOrganisationDocumentMetadata(input: $input) {
      clientMutationId
    }
  }
`

export const AMEND_DOCUMENT_MUTATION = gql`
  mutation AmendDocument($input: AmendDocumentInput!) {
    amendDocument(input: $input) {
      clientMutationId
    }
  }
`

const CREATE_DOCUMENT_FACILITY_PARTIAL = `
createDocumentFacility(input: $input) {
  clientMutationId
  documentFacility {
    id
  }
}`

export const CREATE_DOCUMENT_FACILITY_MUTATION = gql`
mutation CreateDocumentFacility($input: CreateDocumentFacilityInput!) {
 ${CREATE_DOCUMENT_FACILITY_PARTIAL}
}
`

export const UPDATE_FACILITY_MUTATION = gql`
  mutation UpdateDocumentFacility($input: UpdateDocumentFacilityInput!) {
    updateDocumentFacility(input: $input) {
      clientMutationId
    }
  }
`

export const CREATE_FACILITY_APPLICATION_MUTATION = gql`
  mutation CreateFacilityApplication($input: CreateFacilityApplicationInput!) {
    createFacilityApplication(input: $input) {
      clientMutationId
      facilityApplication {
        id
      }
    }
  }
`

export const UPDATE_FACILITY_APPLICATION_MUTATION = gql`
  mutation UpdateFacilityApplication($input: UpdateApplicationInput!) {
    updateFacilityApplication(input: $input) {
      clientMutationId
      facilityApplication {
        id
        applicant {
          abn
          address
          companyName
          contactName
          contactTitle
          emailAddress
          phoneNumber
          website
        }
        applicationInformation {
          facilityLimit
        }
        attachments {
          id
          contentType
          fileName
          received
          uploadPresignedUrl
          variant
        }
        sharedFacilityWithOrganisations {
          organisationId
          createdAt
          id
          facilityApplicationId
          sharedWithOrganisation {
            name
          }
        }
        organisationId
        organisation {
          name
        }
      }
    }
  }
`

export const SUBMIT_FACILITY_APPLICATION_MUTATION = gql`
  mutation SubmitFacilityApplication($input: SubmitApplicationInput!) {
    submitFacilityApplication(input: $input) {
      facilityApplication {
        id
        attachments {
          id
          contentType
          fileName
          received
          uploadPresignedUrl
          variant
        }
      }
    }
  }
`

export const CREATE_FACILITY_APPLICATION_ATTACHMENT_MUTATION = gql`
  mutation CreateFacilityApplicationAttachment($input: CreateFacilityApplicationAttachmentInput!) {
    createFacilityApplicationAttachment(input: $input) {
      clientMutationId
      facilityApplicationAttachment {
        id
        contentType
        fileName
        received
        uploadPresignedUrl
      }
    }
  }
`

export const REMOVE_FACILITY_APPLICATION_ATTACHMENT_MUTATION = gql`
  mutation RemoveFacilityApplicationAttachment($input: RemoveFacilityApplicationAttachmentInput!) {
    removeFacilityApplicationAttachment(input: $input) {
      clientMutationId
    }
  }
`

export const DRAFT_BG_SUBMISSION_MUTATION = gql`
  mutation UpdateDraft($input: UpdateDraftInput!) {
    updateDraft(input: $input) {
      clientMutationId
    }
  }
`
export const NOT_INTEREST_IN_FINANCE = gql`
  mutation ClientNotInterestedInFinance($draftId: ID!) {
    clientNotInterestedInFinance(draftId: $draftId)
  }
`

export const IDENTIFICATION_UPDATE_MUTATION = gql`
  mutation UpdateIdentification($input: UpdateIdentificationInput!) {
    updateIdentification(input: $input) {
      clientMutationId
      applicableRequirements {
        field
        message
        requirementMet
      }
    }
  }
`
export const IDENTIFICATION_SAVE_MUTATION = gql`
  mutation SaveIdentification($input: UpdateIdentificationInput!) {
    saveIdentification(input: $input) {
      clientMutationId
    }
  }
`

export const CREATE_DRAFT_FROM_ORIGINATION = gql`
  mutation CreateDraftFromOrigination($input: CreateDraftFromOriginationInput!) {
    createDraftFromOrigination(input: $input) {
      clientMutationId
      draftId
      organisationId
    }
  }
`

export const PREFILL_IDENTIFICATION = gql`
  mutation prefill($input: PrefillIdentificationInput!) {
    prefillIdentification(input: $input) {
      clientMutationId
    }
  }
`

export const UPDATE_SIMPLE_KYC_DATA = gql`
  mutation updateSimpleKycDataFromApiByIdentificationId($input: UpdateSimpleKycDataAppInput!) {
    updateSimpleKycDataFromApiByIdentificationId(input: $input) {
      clientMutationId
    }
  }
`
export const RESEND_ADDITIONAL_INVOICES = gql`
  mutation ResendInvoices($input: String, $referenceNumber: String, $clientMutationId: String) {
    resendInvoices(input: $input, referenceNumber: $referenceNumber, clientMutationId: $clientMutationId) {
      clientMutationId
      allRecipients
    }
  }
`

export const CREATE_FINANCE_ENQUIRY = gql`
  mutation createFinanceEnquiry($financeEnquiry: FinanceEnquiryInput!) {
    createFinanceEnquiry(financeEnquiry: $financeEnquiry) {
      contactFirstName
    }
  }
`
export const SET_BG_APPLICATION_CONTACT_DETAILS = gql`
  mutation SetApplicationContactDetails($input: ClientPortalApplicationContactDetailsInput!) {
    setApplicationContactDetails(input: $input) {
      draftId
    }
  }
`
export const CREATE_REQUIRED_DOCUMENT_MESSAGE = gql`
  mutation CreateRequiredDocumentMessage($input: CreateRequiredDocumentMessageInput!) {
    createRequiredDocumentMessage(input: $input) {
      id
    }
  }
`

export const ACTION_REQUIRED_DOCUMENT_MESSAGE = gql`
  mutation actionRequiredDocumentMessage($input: ActionRequiredDocumentMessageInput!) {
    actionRequiredDocumentMessage(input: $input) {
      id
    }
  }
`

export const CREATE_DRAFT_BG_SWITCH = gql`
  mutation CreateDraftBGSwitch($input: CreateDraftBGSwitchInput!) {
    createDraftBGSwitch(input: $input) {
      draftBGSwitch {
        id
      }
    }
  }
`

export const UPDATE_DRAFT_BG_SWITCH = gql`
  mutation UpdateDraftBGSwitch($input: UpdateDraftBGSwitchInput!) {
    updateDraftBGSwitch(input: $input) {
      draftBGSwitch {
        id
      }
    }
  }
`
