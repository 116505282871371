import store from '@/store'
import { parse } from 'date-fns'
import MixPanel from 'mixpanel-browser'
import {
  DocumentVariant,
  DraftProcessType,
  JourneyEventData,
  JourneyInteraction,
  JourneyInteractionData,
  JourneyMeta,
  JourneyPage,
  MetaUser,
  TrackedAppArea,
  UserData
} from '~/types'
import { Keyed, ObjNonNull } from '~/types/typeHelpers'
import { timeDuration } from '~/utils/time'

export const trackEvent = (eventName: string, trackData: {}) => {
  if (store.getters.appConfig.mixPanel.enabled) {
    return MixPanel.track(eventName, trackData)
  }
  return true
}

export const trackUserLogin = (userId: string, userData: {}) => {
  if (store.getters.appConfig.mixPanel.enabled) {
    MixPanel.identify(userId)
    return MixPanel.people.set(userData)
  }
}

/**
 * Track an event as well as the duration from the provided `startDate` to now.
 * @param eventName - Name of the event in tracking software.
 * @param startDate - The start date when the page/section component 1st initialized.
 * @param trackData - Supplementary data to associate with the event.
 */
export const trackEventWithTime = (eventName: string, startDate: Date, trackData: {}) => {
  const now: Date = new Date()
  const dataWithDuration = {
    timeStart: startDate.toISOString(),
    timeEnd: now.toISOString(),
    duration: startDate ? timeDuration(startDate, now) : 'Not Provided',
    ...trackData
  }
  if (store.getters.appConfig.mixPanel.enabled) {
    return MixPanel.track(eventName, dataWithDuration)
  }
  return true
}

/** Returns value passed in or otherwise placeholder text. */
export const valueOrPlaceholder = <T>(v: T): NonNullable<T | 'Not Provided'> => v ?? 'Not Provided'

/** Check that an object is one and not an array or something with another constructor. */
const checkIsObj = (v: any): boolean => !!v && typeof v === 'object' && !Array.isArray(v) && v?.constructor === Object

/**
 * Replace missing values with "Not Provided" placeholder text.
 * Ensure the properties are not lost before passing the object in.
 */
export const withPlaceholders = <T>(objIn: Keyed<T>): ObjNonNull<T> => {
  if (!objIn) return objIn

  const objOut = Object.keys(objIn).reduce((acc, key) => {
    const value = objIn[key]
    const isObject = checkIsObj(value)
    const updatedValue = isObject
      ? withPlaceholders((value as unknown) as Keyed<typeof value>)
      : valueOrPlaceholder(value)

    const updatedObj: ObjNonNull<T> = {
      ...acc,
      [key]: updatedValue
    } as ObjNonNull<T>

    return updatedObj
  }, {} as ObjNonNull<T>)

  return objOut
}

/**
 * For a given document variant assigned to a document,
 * select the appropriate process area name that is
 * being used for that variant in our tracking.
 */
export const docVariantToProcessArea = (variant: DocumentVariant): TrackedAppArea => {
  switch (variant) {
    case DocumentVariant.BANK_GUARANTEE:
      return TrackedAppArea.BG_PROCESS
    case DocumentVariant.CASH:
      return TrackedAppArea.CASH_PROCESS
    case DocumentVariant.LEASE_BOND:
      return TrackedAppArea.LEASE_BOND
    case DocumentVariant.SURETY_BOND:
      return TrackedAppArea.SURETY_BONDS_PROCESS
  }
}

/**
 * For a given Draft Process Type, identify an appropriate area name.
 */
export const draftProcessTypeToProcessArea = (draftType: DraftProcessType) => {
  switch (draftType) {
    case DraftProcessType.BANK_GUARANTEE:
      return TrackedAppArea.BG_PROCESS
    case DraftProcessType.SURETY_BOND:
      return TrackedAppArea.SURETY_BONDS_PROCESS
  }
}

/**
 * Track when the user leaves the page as part of their journey.
 * Differs from a typical page view that may track on page load.
 */
export const trackNavigation = (journey: JourneyPage, extraData: Keyed<JourneyMeta>) => {
  const trackData: JourneyEventData = {
    ...withPlaceholders(parseMeta(extraData)),
    'Area Name': journey.areaName,
    'Page Name': journey.pageName
  }

  trackEventWithTime(`${journey.pageName}${journey.noPageInPageName ? '' : ` Page`}`, journey.startDate, trackData)
  trackEventWithTime('Page Navigated', journey.startDate, trackData)
}

export const trackInteraction = (journey: JourneyInteraction) => {
  const data: JourneyInteractionData = {
    Kind: journey.kind,
    'Element Text': journey.elText,
    Extra: valueOrPlaceholder(journey.extra),
    When: journey.when ?? new Date()
  }

  trackEvent('Interaction', data)
}

const locateField = (el: HTMLElement, withChildQuery?: string): HTMLElement | null => {
  const parent = el.parentElement
  if (!parent) return null
  const parentIsField = parent?.classList.contains('field')
  const parentHasChild = withChildQuery ? parent.querySelector(withChildQuery) : true
  if (parentIsField && parentHasChild) return parent
  return locateField(parent, withChildQuery)
}

/**
 * Locate the label text, if it exists.
 * Sometimes labels are not semantic and sometimes the input is nested in 2 fields.
 * This helps when the label is not in `input.labels`.
 */
const locateLabelText = (el: HTMLElement): string | null => {
  // usually you want the label above all the options, rather than the option
  const elLabel = locateField(el, 'label:not(.radio, .checkbox)')?.querySelector(
    'label:not(.radio, .checkbox)'
  ) as HTMLElement | null
  if (elLabel) return elLabel?.innerText ?? null

  // other-times the label is more specific
  const elRadioLabel = locateField(el, 'label')?.querySelector('label')
  return (elRadioLabel?.innerText || elRadioLabel?.getAttribute('data-cy-tag')) ?? null
}

/**
 * Track an interaction on an `<input>`.
 */
const interactionInput = (target: HTMLInputElement) => {
  const mainLabelEl = Array.from(target.labels || []).pop()
  const inputLabel =
    locateLabelText(target) ||
    mainLabelEl?.innerText ||
    target.getAttribute('placeholder') ||
    mainLabelEl?.getAttribute('data-cy-tag') ||
    target.getAttribute('data-cy-tag') ||
    null
  trackInteraction(
    withPlaceholders({
      kind: target.tagName,
      // for an INPUT we don't want its value
      elText: inputLabel,
      extra: target.getAttribute('name')
    })
  )
}

/**
 * Track an interaction on a `<button>` or an `<a>`.
 */
const interactionButtonOrLink = (target: HTMLAnchorElement | HTMLButtonElement) => {
  trackInteraction(
    withPlaceholders({
      kind: target.tagName,
      elText: target.innerText || 'Not Provided'
    })
  )
}

/**
 * Track an interaction from an event, such as a click.
 * Also able to add listeners afterwards to catch new DOM elements.
 * @param e - The Event
 * @param refreshListeners - Whether to add listeners again.
 */
const interactionListener = (e: Event, refreshListeners = true) => {
  let target = e.target as HTMLInputElement | HTMLAnchorElement | HTMLButtonElement
  if (target.tagName === 'SPAN' && target.parentElement?.tagName === 'BUTTON')
    target = target.parentElement as HTMLButtonElement

  if (['OPTION'].includes(target.tagName)) return

  if (['INPUT', 'SELECT', 'TEXTAREA'].includes(target.tagName)) interactionInput(target as HTMLInputElement)
  else interactionButtonOrLink(target as HTMLAnchorElement | HTMLButtonElement)

  if (refreshListeners) {
    requestAnimationFrame(() => removeInteractionTracking())
    delayedAddInteractionTracking()
  }
}

/**
 * Elements to track when focused.
 * Note: "<select>" must be focus and not click as otherwise double-trigger.
 */
const interactionElSelectorsFocus: string = 'input, select, textarea'

/**
 * Elements to track only when clicked.
 * Note: "<select>" must be focus as otherwise double-trigger.
 */
const interactionElSelectorsClicks: string = 'a, button'

const interactionListenerOptions = {
  once: true
}

/**
 * Add interaction tracking to select interaction elements on the page.
 */
export const addInteractionTracking = (elPage: HTMLElement | Document = document) => {
  elPage.querySelectorAll(interactionElSelectorsFocus).forEach(el => {
    el.addEventListener('focus', interactionListener, interactionListenerOptions)
    // on change we don't want to track but instead want to pick up anything else that appears
    el.addEventListener('change', delayedAddInteractionTracking, interactionListenerOptions)
  })

  elPage.querySelectorAll(interactionElSelectorsClicks).forEach(el => {
    el.addEventListener('click', interactionListener, interactionListenerOptions)
  })
}

export const delayedAddInteractionTracking = () => {
  requestAnimationFrame(() => addInteractionTracking())
  // sometimes animations delay bind-ability. No resulting double triggers.
  setTimeout(() => addInteractionTracking(), 2000)
}

/**
 * Remove interaction tracking on interaction elements on the page.
 */
export const removeInteractionTracking = (elPage: HTMLElement | Document = document) => {
  elPage.querySelectorAll(interactionElSelectorsFocus).forEach(el => {
    el.removeEventListener('focus', interactionListener)
    el.removeEventListener('change', delayedAddInteractionTracking)
  })

  elPage.querySelectorAll(interactionElSelectorsClicks).forEach(el => {
    el.removeEventListener('click', interactionListener)
  })
}

/**
 * Consistently populate user metadata based on current user data.
 */
export const metaUser = (userData: UserData): MetaUser =>
  withPlaceholders({
    name: `${userData.givenName} ${userData.familyName}`,
    job: userData.jobTitle,
    industry: userData.industry
  } as Keyed<MetaUser>)

export const parseMeta = (dataIn: Keyed<JourneyMeta>): Keyed<JourneyMeta> => {
  const dataOut = { ...dataIn }

  if (typeof dataOut?.BG_ExpiryDate === 'object') {
    dataOut.BG_ExpiryDate = (dataOut.BG_ExpiryDate as Date)?.toISOString()
  } else if (typeof dataOut?.BG_ExpiryDate === 'string') {
    try {
      dataOut.BG_ExpiryDate = parse(dataOut.BG_ExpiryDate, 'yyyy-MM-dd', new Date())?.toISOString()
    } catch (e) {
      // date is in another format and will be passed as-is
    }
  }

  return dataOut
}
