import { PageInfo } from './graphql'

export interface TablesPageInfo {
  endCursor: string
  hasNextPage: boolean
  hasPreviousPage: boolean
  startCursor: string
}

export enum SortOrder {
  asc = 'asc',
  desc = 'desc'
}

/**
 * Table for records. Has less features than ResultsTable.
 */
export interface BasicRecordsTable<Row = unknown> {
  isLoading: boolean
  nodes?: Row[]
  sortField: Row extends {} ? keyof Row : string
  sortOrder: SortOrder
}

/**
 * Table for search results.
 *
 * **Note:** it may make sense to have a simpler `Table` interface as well as this interface.
 */
export interface ResultsTable<Row = unknown> {
  search?: string

  nodes: Row[]
  totalCount: number
  initialPageInfo: TablesPageInfo | PageInfo
  pageInfo: TablesPageInfo | PageInfo
  sliceNotFirstPageSlice: boolean

  isLoading: boolean
  page: number
  perPage: number
  sortField: Row extends {} ? keyof Row : string
  sortOrder: SortOrder
  defaultSortOrder: SortOrder

  /**
   * Fetch results and populate the fields
   */
  fetchResults(): void

  /**
   * Reload when a document ID changes
   */
  reloadTable(arg0: any): void

  /**
   * When page changes, update to reflect the page
   */
  onPageChange(page: number): void

  /**
   * On sort reset the navigation, query, and then update
   */
  onSort(field: keyof Row | string, order: SortOrder): void

  /**
   * Click handler, can be whatever.
   */
  onClick?(rows: Row): void
}
