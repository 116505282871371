import { AppConfig } from '@/config'
import { queries, subscriptions } from '@/graphql'
import { apolloClient, containsErrorCode } from '@/graphql/client'
import { Document, Draft } from '@/graphql/types'
import router from '@/router'
import { ApolloError } from 'apollo-client'
import { LoadingProgrammatic, ToastProgrammatic } from 'buefy'
import Vue from 'vue'
import Vuex, { ActionTree, GetterTree, MutationTree } from 'vuex'
import bgSubmissionModule from './bgSubmission'
import cognitoModule, { IS_LOGGED_IN } from './cognito'
import documentModule from './document'
import facilityModule from './facility'
import importModule from './import'
import requiredDocumentsModule from './requiredDocuments'
import userModule from './user'

Vue.use(Vuex)

// Vuex module names
export const AUTH_MOD = 'auth'
export const USER_MOD = 'user'
export const DOCUMENT_MOD = 'document'
export const FACILITY_MOD = 'facility'
export const BG_SUBMISSION_MOD = 'bgSubmission'
export const REQUIRED_DOCUMENTS_MOD = 'requiredDocuments'

// Actions
export const INIT = 'init'
export const FETCH_DOCUMENT = 'fetchDocument'
export const SUBSCRIBE_DOCUMENT = 'subscribeDocument'
export const CLEAR_DOCUMENT = 'clearDocument'
export const FETCH_DRAFT = 'fetchDraft'
export const SUBSCRIBE_DRAFT = 'subscribeDraft'
export const CLEAR_DRAFT = 'clearDraft'
export const IMPORT_MOD = 'import'

// Mutations
export const SET_READY = 'setReady'
export const SET_CONFIG = 'setConfig'
const SET_ACTIVE_DOCUMENT = 'setActiveDocument'
const SET_ACTIVE_DOCUMENT_SUBSCRIPTION = 'setActiveDocumentSubscription'
const SET_ACTIVE_DRAFT = 'setActiveDraft'
const SET_ACTIVE_DRAFT_SUBSCRIPTION = 'setActiveDraftSubscription'

// Getters
export const APP_CONFIG = 'appConfig'
export const ACTIVE_DOCUMENT = 'activeDocument'
export const ACTIVE_DRAFT = 'activeDraft'
export const ACTIVE_DRAFT_DETAILS = 'activeDraftDetails'

export interface ActiveDraftDetails {
  draftId: string
  organisationId: string
}

interface SetActiveDraftInput extends ActiveDraftDetails {
  draft: Draft
}

export interface RootState {
  ready: boolean
  config: AppConfig | null
  activeDocument: Document | null
  activeDocumentSubscription: ZenObservable.Subscription | null
  activeDraft: Draft | null
  activeDraftSubscription: ZenObservable.Subscription | null
  activeDraftDetails: ActiveDraftDetails | null
}

const actions: ActionTree<RootState, RootState> = {
  [INIT]({ commit, dispatch }) {
    const loader = LoadingProgrammatic.open({})
    return Promise.all([dispatch(`${AUTH_MOD}/${INIT}`)])
      .then(() => commit(SET_READY, true))
      .finally(() => loader.close())
  },
  [FETCH_DOCUMENT]({ commit, dispatch }, documentId) {
    return apolloClient()
      .query({
        query: queries.DOCUMENT_QUERY,
        variables: {
          documentId
        }
      })
      .then(({ data: { document } }) => {
        commit(SET_ACTIVE_DOCUMENT, document)
        dispatch(SUBSCRIBE_DOCUMENT, documentId)
        return document
      })
      .catch((error: ApolloError) => {
        if (containsErrorCode(error, 'DOCUMENT_NOT_FOUND')) {
          ToastProgrammatic.open({
            type: 'is-danger',
            message: 'Document could not be found.'
          })
          router.push({ name: 'default' })
        } else {
          const { message } = error
          ToastProgrammatic.open({
            type: 'is-danger',
            message
          })
        }
      })
  },
  [SUBSCRIBE_DOCUMENT]({ commit }, documentId) {
    const subscription = apolloClient()
      .subscribe({
        query: subscriptions.DOCUMENT_UPDATED_QUERY,
        variables: {
          documentId
        }
      })
      .subscribe({
        next: ({ data: { documentUpdated } }) => commit(SET_ACTIVE_DOCUMENT, documentUpdated),
        error: ({ message }: ApolloError) =>
          ToastProgrammatic.open({
            type: 'is-danger',
            message
          })
      })
    commit(SET_ACTIVE_DOCUMENT_SUBSCRIPTION, subscription)
  },
  [CLEAR_DOCUMENT]({ commit }) {
    if (state.activeDocumentSubscription) {
      state.activeDocumentSubscription.unsubscribe()
      commit(SET_ACTIVE_DOCUMENT_SUBSCRIPTION, null)
    }
    if (state.activeDocument) {
      commit(SET_ACTIVE_DOCUMENT, null)
    }
  },
  [FETCH_DRAFT]({ commit, dispatch }, { draftId, organisationId }) {
    return apolloClient()
      .query({
        query: queries.DRAFT_QUERY,
        variables: {
          draftId,
          organisationId
        }
      })
      .then(({ data: { draft } }) => {
        commit(SET_ACTIVE_DRAFT, { draft, draftId, organisationId })
        dispatch(SUBSCRIBE_DRAFT, { draftId, organisationId })
        return draft
      })
      .catch((error: ApolloError) => {
        if (containsErrorCode(error, 'DRAFT_NOT_FOUND')) {
          ToastProgrammatic.open({
            type: 'is-danger',
            message: 'Draft could not be found.'
          })
          router.push({ name: 'default' })
        } else {
          const { message } = error
          ToastProgrammatic.open({
            type: 'is-danger',
            message
          })
        }
      })
  },
  [SUBSCRIBE_DRAFT]({ commit }, { draftId, organisationId }) {
    if (state.activeDraftSubscription) {
      state.activeDraftSubscription.unsubscribe()
    }
    const subscription = apolloClient()
      .subscribe({
        query: subscriptions.DRAFT_UPDATED_QUERY,
        variables: {
          draftId,
          organisationId
        }
      })
      .subscribe({
        next: ({ data: { draftUpdated } }) =>
          commit(SET_ACTIVE_DRAFT, {
            draft: { ...state.activeDraft, ...draftUpdated },
            draftId,
            organisationId
          }),
        error: ({ message }: ApolloError) =>
          ToastProgrammatic.open({
            type: 'is-danger',
            message
          })
      })
    commit(SET_ACTIVE_DRAFT_SUBSCRIPTION, subscription)
  },
  [CLEAR_DRAFT]({ commit }) {
    if (state.activeDraftSubscription) {
      state.activeDraftSubscription.unsubscribe()
      commit(SET_ACTIVE_DRAFT_SUBSCRIPTION, null)
    }
    if (state.activeDraft) {
      commit(SET_ACTIVE_DRAFT, null)
    }
  }
}

const state: RootState = {
  ready: false,
  config: null,
  activeDocument: null,
  activeDocumentSubscription: null,
  activeDraft: null,
  activeDraftSubscription: null,
  activeDraftDetails: null
}

const mutations: MutationTree<RootState> = {
  [SET_READY](state, status: boolean) {
    state.ready = status
  },
  [SET_CONFIG](state, config: AppConfig) {
    state.config = config
  },
  [SET_ACTIVE_DOCUMENT](state, document: Document) {
    state.activeDocument = document
  },
  [SET_ACTIVE_DOCUMENT_SUBSCRIPTION](state, subscription: ZenObservable.Subscription) {
    state.activeDocumentSubscription = subscription
  },
  [SET_ACTIVE_DRAFT](state, draftDetails: SetActiveDraftInput) {
    if (draftDetails) {
      state.activeDraft = draftDetails.draft
      state.activeDraftDetails = {
        draftId: draftDetails.draftId,
        organisationId: draftDetails.organisationId
      }
    } else {
      state.activeDraft = null
      state.activeDraftDetails = null
    }
  },
  [SET_ACTIVE_DRAFT_SUBSCRIPTION](state, subscription: ZenObservable.Subscription) {
    state.activeDraftSubscription = subscription
  }
}

const getters: GetterTree<RootState, RootState> = {
  isReady({ ready }) {
    return ready
  },
  isAuthenticated() {
    return store.getters[`${AUTH_MOD}/${IS_LOGGED_IN}`]
  },
  [APP_CONFIG]: ({ config }) => config,
  [ACTIVE_DOCUMENT]: ({ activeDocument }) => activeDocument,
  [ACTIVE_DRAFT]: ({ activeDraft }) => activeDraft,
  [ACTIVE_DRAFT_DETAILS]: ({ activeDraftDetails }) => activeDraftDetails
}

const store = new Vuex.Store({
  state,
  mutations,
  actions,
  getters,
  modules: {
    [AUTH_MOD]: cognitoModule,
    [USER_MOD]: userModule,
    [DOCUMENT_MOD]: documentModule,
    [IMPORT_MOD]: importModule,
    [FACILITY_MOD]: facilityModule,
    [BG_SUBMISSION_MOD]: bgSubmissionModule,
    [REQUIRED_DOCUMENTS_MOD]: requiredDocumentsModule
  }
})

export default store
export { ID_TOKEN, IS_LOGGED_IN, SIGN_UP } from './cognito'
export { IMPORT_JOB_ID, IMPORT_JOB_SUBSCRIBE, IMPORT_JOB_UPLOAD, SET_IMPORT_JOB_ID } from './import'
export {
  CLEAR_REQUIRED_DOCUMENTS,
  FETCH_REQUIRED_DOCUMENTS,
  REQUIRED_DOCUMENTS,
  SUBSCRIBE_REQUIRED_DOCUMENTS
} from './requiredDocuments'
export {
  ALL_ORGANISATIONS,
  CLOSE_INIT_PROMPT,
  FETCH_USER_STATE,
  INIT_PROMPT_SHOWING,
  OPEN_INIT_PROMPT,
  ORGANISATION,
  ORGANISATION_ID,
  ORGANISATION_REQUIRED,
  OTHER_ORGANISATIONS,
  PROFILE,
  RELOAD_ORGANISATIONS,
  REQUIRES_CREATE_ORGANISATION_AND_TOU_ACCEPT_PROMPT,
  REQUIRES_CREATE_ORGANISATION_PROMPT,
  REQUIRES_TOU_ACCEPT_PROMPT,
  SET_INIT_PROMPT_PROPS,
  SET_ORGANISATION_ID,
  SET_ORGANISATION_REQUIRED,
  SET_PROFILE,
  SET_TABLE_SORT_ORDER,
  TABLE_SORT_ORDER,
  USER_GATEWAY_FEATURES
} from './user'
