//export const toAbn = (input: string) => input.replace(/ /g, '').replace(/(\d{2})(\d{3})(\d{3})(\d{3})/, '$1 $2 $3 $4')
export const toAbnOrAcn = (input: string) => {
  if (isAbnLength(input)) return input.replace(/ /g, '').replace(/(\d{2})(\d{3})(\d{3})(\d{3})/, '$1 $2 $3 $4')
  if (isAcnLength(input)) return input.replace(/ /g, '').replace(/(\d{3})(\d{3})(\d{3})/, '$1 $2 $3')
  return input
}

export const isAbnLength = (value: string) => value.replace(/\s/g, '').length == 11

export const isAcnLength = (value: string) => value.replace(/\s/g, '').length == 9

export const abnOrAcnLabel = (value: string) => {
  if (value && isAbnLength(value)) return 'ABN'
  if (value && isAcnLength(value)) return 'ACN'
  return 'ABN/ACN'
}
