import { ToastProgrammatic } from 'buefy'
import { saveAs } from 'file-saver'
import { unparse } from 'papaparse'

export const handleExportToCSV = (exportRows: any, fileName: string, headerLabels?: string[]) => {
  try {
    if (exportRows.length == 0) {
      ToastProgrammatic.open({
        message: 'There is no data available to export',
        type: 'is-warning'
      })
      return
    }
    const data = unparse(exportRows, {
      quotes: true,
      quoteChar: '"',
      escapeChar: '"',
      delimiter: ',',
      header: true,
      newline: '\r\n',
      skipEmptyLines: false,
      escapeFormulae: false,
      columns: headerLabels
    })
    const blob = new Blob([data], {
      type: 'text/plain;charset=utf-8'
    })
    saveAs(blob, `${fileName}.csv`)
  } catch (error) {
    ToastProgrammatic.open({
      message: (error as Error).message,
      type: 'is-danger'
    })
  }
}
