import { DraftInvoice, InvoicePaymentIntent } from '~/types/graphql'

//checking invoice statuses
export const isInvoiceOpen = (invoice: DraftInvoice) => {
  return invoice.stripeInvoiceStatus === 'open'
}
export const isInvoicePaid = (invoice: DraftInvoice) => {
  return invoice.stripeInvoiceStatus === 'paid'
}
export const isInvoiceVoid = (invoice: DraftInvoice) => {
  return invoice?.stripeInvoiceStatus === 'void'
}

//filtering invoices
export const getOpenInvoices = (invoices: DraftInvoice[]) => {
  return invoices?.filter(invoice => invoice.stripeInvoiceStatus === 'open')
}
export const getVoidInvoices = (invoices: DraftInvoice[]) => {
  return invoices?.filter(invoice => invoice.stripeInvoiceStatus === 'void')
}
export const getPaidInvoices = (invoices: DraftInvoice[]) => {
  return invoices?.filter(invoice => invoice.stripeInvoiceStatus === 'paid')
}

//filter payment intents
export const getRequiredPaymentIntents = (invoicePaymentIntents: (InvoicePaymentIntent | null)[] | undefined) => {
  return invoicePaymentIntents?.filter(intent => intent?.status === 'requires_payment_method')
}
export const getProcessingPaymentIntents = (invoicePaymentIntents: (InvoicePaymentIntent | null)[] | undefined) => {
  return invoicePaymentIntents?.filter(intent => intent?.status === 'processing')
}
export const getRequireActionPaymentIntents = (invoicePaymentIntents: (InvoicePaymentIntent | null)[] | undefined) => {
  return invoicePaymentIntents?.filter(intent => intent?.status === 'requires_action')
}
export const getSucceededPaymentIntents = (invoicePaymentIntents: (InvoicePaymentIntent | null)[] | undefined) => {
  return invoicePaymentIntents?.filter(intent => intent?.status === 'succeeded')
}

//checking payment Intent statuses
export const isPaymentIntentRequiresPayment = (invoicePaymentIntent: InvoicePaymentIntent | undefined) => {
  if (!invoicePaymentIntent) return
  else return invoicePaymentIntent?.status === 'requires_payment_method'
}
export const isPaymentIntentProcessing = (invoicePaymentIntent: InvoicePaymentIntent | undefined) => {
  if (!invoicePaymentIntent) return
  else return invoicePaymentIntent.status === 'processing'
}
export const isPaymentIntentRequiresRequiresAction = (invoicePaymentIntent: InvoicePaymentIntent | undefined) => {
  if (!invoicePaymentIntent) return
  else return invoicePaymentIntent.status === 'requires_action'
}
export const isPaymentIntentRequiresSucceeded = (invoicePaymentIntent: InvoicePaymentIntent | undefined) => {
  if (!invoicePaymentIntent) return
  else return invoicePaymentIntent.status === 'succeeded'
}
