import { TableSort } from '@/graphql/types'
import store, { SET_TABLE_SORT_ORDER, USER_MOD } from '@/store'
import { namespaceWrapper } from '@/utils'
import { SortOrder } from '~/types'

export const updateUserTableSort = (
  tableName: string,
  tableTab: string | null,
  sortField: string,
  sortOrder: SortOrder | string
) => {
  const user = namespaceWrapper(USER_MOD)
  const sortDetails: TableSort = {
    tableName: tableName,
    tableTab: tableTab,
    sortField,
    sortOrder: sortOrder as string
  }
  store.commit(user(SET_TABLE_SORT_ORDER), sortDetails)
}
