export enum TrackedAppArea {
  BG_PROCESS = 'Bank Guarantee Process',
  CASH_PROCESS = 'Cash Process',
  LEASE_BOND = 'Lease Bond Process',
  SURETY_BONDS_PROCESS = 'Surety Bonds Process'
}

export interface Journey {
  /** Area of site such as BG Process, Surety Bonds Process etc */
  areaName: TrackedAppArea
  /** Name of the page. Best consistent with name in other events on page. */
  pageName: string
  /** When the page first initialized or loaded */
  startDate: Date
}

export interface MetaUser {
  name: string
  job: string
  industry: string
}

export interface MetaBG {
  BG_Amount?: string | null
  BG_ExpiryDate?: string | Date | null
  selectedProduct?: string | null
}

export interface JourneyMeta extends MetaBG {
  user?: MetaUser
}

export interface JourneyPage extends Journey {
  noPageInPageName?: boolean
}

export interface PageEventData {
  'Area Name': TrackedAppArea
  'Page Name': string
}

export interface JourneyEventData extends PageEventData, JourneyMeta {}

export interface JourneyInteraction {
  kind: string
  elText: string
  extra?: string | null
  when?: Date | null
}

/**
 * The interaction data is generally supplementary, and has no meaning
 * outside of the neighboring events that give it context.
 */
export interface JourneyInteractionData {
  Kind: string
  /**
   * Element text is usually button text.
   * **Be careful not to collect sensitive info,**
   * like that of an input.
   */
  'Element Text': string
  /**
   * Additional text to help identify element
   */
  Extra: string
  /**
   * When represents the exact moment that the user interacted with it.
   */
  When: Date
}

export interface UserData {
  givenName?: string | null
  familyName?: string | null
  jobTitle?: string | null
  industry?: string | null
}
