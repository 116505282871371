export const sleep = (ms: number) => {
  return new Promise(resolve => setTimeout(resolve, ms))
}

const waitFor = async (callback: () => boolean, maxRetries: number, returnValue: any, timeoutValue: any) => {
  let attemptedRetries = 0
  while (!callback()) {
    await sleep(50)
    attemptedRetries++
    if (attemptedRetries >= maxRetries) {
      return timeoutValue
    }
  }

  return returnValue
}

const waitForLoadedElement = async (id: string): Promise<HTMLElement | null> => {
  return await waitFor(
    () => {
      const existingElement = document.getElementById(id)
      return !!existingElement && existingElement.hasAttribute('loaded')
    },
    100,
    document.getElementById(id),
    null
  )
}

const appendElement = (element: HTMLElement) => {
  return new Promise<void>(resolve => {
    element.onload = () => {
      resolve()
    }
    document.head.appendChild(element)
  })
}

const creatingElements: any = {}
export const loadScript = async (id: string, src: string, async: boolean = true) => {
  // only allow single attempt to load
  if (creatingElements[id]) {
    return await waitForLoadedElement(id)
  }

  creatingElements[id] = true

  // create script
  const script = document.createElement('script')
  script.id = id
  script.async = async
  script.src = src

  await appendElement(script)
  script.setAttribute('loaded', 'true')
}
