declare module 'buefy/types' {
  export const Field: any
  export const Select: any
  export const Input: any
  export const Button: any
  export const Loading: any
  export const Icon: any
  export const Upload: any
  export const Datepicker: any
  export const Dialog: any
  export const Dropdown: any
  export const DropdownItem: any
  export const Notification: any
  export const Table: any
  export const TableColumn: any
  export const Radio: any
  export const RadioButton: any
  export const Checkbox: any
  export const Autocomplete: any
  export const Tabs: any
  export const TabItem: any
  export const Menu: any
  export const MenuList: any
  export const MenuItem: any
  export const Message: any
  export const Tooltip: any
  export const Tag: any
  export const Taginput: any
  export const Switch: any
  export const Toast: any
  export const Modal: any
  export const Collapse: any
  export const Snackbar: any
  export const Sidebar: any
}

import App from '@/App.vue'
import { AppConfig, getConfig } from '@/config'
import { vueApolloClient } from '@/graphql/client'
import router from '@/router'
import store, { SET_CONFIG } from '@/store'
import { registerValidators } from '@/utils'
import VueGtm from '@gtm-support/vue2-gtm'
import * as Sentry from '@sentry/browser'
import { Vue as VueIntegration } from '@sentry/integrations'
import { StripePlugin } from '@vue-stripe/vue-stripe'
import {
  Autocomplete,
  Button,
  Checkbox,
  Collapse,
  ConfigProgrammatic,
  Datepicker,
  Dialog,
  Dropdown,
  Field,
  Icon,
  Input,
  Loading,
  Menu,
  Message,
  Modal,
  Notification,
  Radio,
  Select,
  Sidebar,
  Snackbar,
  Switch,
  Table,
  Tabs,
  Tag,
  Taginput,
  Toast,
  Tooltip,
  Upload
} from 'buefy'
import { parse } from 'date-fns'
import isEmpty from 'lodash'
import isArray from 'lodash/isArray'
import MixPanel from 'mixpanel-browser'
import { setInteractionMode } from 'vee-validate'
import Vue from 'vue'
import VueApollo from 'vue-apollo'
import VueGtag from 'vue-gtag'
import { VueReCaptcha } from 'vue-recaptcha-v3'

const BaseLayout = () => import('@/layouts/BaseLayout.vue')
const NavBarLayout = () => import('@/layouts/NavBarLayout.vue')
const RightSidebarLayout = () => import('~/layouts/RightSidebarLayout.vue')
const BaseInput = () => import('~/components/BaseInput.vue')
const BaseSelect = () => import('~/components/BaseSelect.vue')
const BaseRow = () => import('~/components/BaseRow.vue')
const BaseNotification = () => import('~/components/BaseNotification.vue')
const AddressAutocomplete = () => import('~/components/AddressAutocomplete.vue')
const UserAvatar = () => import('~/components/UserAvatar.vue')
const BankAvatar = () => import('~/components/BankAvatar.vue')
const OrganisationAvatar = () => import('~/components/OrganisationAvatar.vue')
const RadioYesNoInput = () => import('~/components/RadioYesNoInput.vue')
const BankGuaranteeAgencyAgreement = () => import('~/components/BankGuaranteeAgencyAgreement.vue')
const StandardFooterApp = () => import('@/components/StandardFooterApp.vue')
const GenericDocumentModal = () => import('~/components/GenericDocumentModal.vue')
const FSGDocumentModal = () => import('@/components/FSGDocumentModal.vue')
const VueSanitize = require('vue-sanitize')

Vue.config.productionTip = false

Vue.use(VueApollo)
Vue.use(Field)
Vue.use(Input)
Vue.use(Select)
Vue.use(Button)
Vue.use(Loading)
Vue.use(Icon)
Vue.use(Upload)
Vue.use(Datepicker)
Vue.use(Dropdown)
Vue.use(Notification)
Vue.use(Message)
Vue.use(Table)
Vue.use(Radio)
Vue.use(Checkbox)
Vue.use(Autocomplete)
Vue.use(Tabs)
Vue.use(Menu)
Vue.use(Tooltip)
Vue.use(Tag)
Vue.use(Taginput)
Vue.use(Switch)
Vue.use(Toast)
Vue.use(Modal)
Vue.use(Dialog)
Vue.use(Collapse)
Vue.use(Snackbar)
Vue.use(Sidebar)
Vue.use(VueSanitize, {
  allowedTags: ['b', 'ul', 'li', 'h1', 'h2', 'h3', 'del', 'ins', 'button', 'div', 'p', 'span'],
  allowedAttributes: { '*': ['class', 'id', 'style'] }
})
const dateSplitter = ' - '
const dateFormatter = (date: Date) => date.toLocaleDateString('en-AU')
const dateParser = (date: string) => parse(date, 'dd/MM/yyyy', new Date())
ConfigProgrammatic.setOptions({
  defaultToastDuration: 4000,
  defaultIconPack: 'feather-icon',
  defaultLinkTags: ['a', 'button', 'input', 'router-link', 'RouterLink'],
  defaultDateParser: (date: string) => {
    if (date.includes(dateSplitter)) {
      return date.split(dateSplitter).map(dateParser)
    }
    return dateParser(date)
  },
  defaultDateFormatter: (dates: Date | Array<Date>): string => {
    if (isArray(dates)) {
      return dates.map(dateFormatter).join(dateSplitter)
    }
    return dateFormatter(dates)
  },
  customIconPacks: {
    'feather-icon': {
      sizes: {
        default: 'is-size-5',
        'is-small': null,
        'is-medium': 'is-size-3',
        'is-large': 'is-size-1'
      },
      iconPrefix: 'icon-',
      internalIcons: {
        check: 'check',
        information: 'info',
        'check-circle': 'check-circle',
        alert: 'alert-triangle',
        'alert-circle': 'alert-circle',
        'arrow-up': 'arrow-up',
        'chevron-right': 'arrow-right',
        'chevron-left': 'arrow-left',
        'chevron-down': 'arrow-down',
        eye: 'eye',
        'eye-off': 'eye-off',
        'menu-down': 'chevron-down',
        'menu-up': 'chevron-up',
        'close-circle': 'x-circle'
      }
    }
  }
})

// Register global components
Vue.component('Layout', BaseLayout) // eslint-disable-line vue/multi-word-component-names
Vue.component('NavBarLayout', NavBarLayout)
Vue.component('RightSidebarLayout', RightSidebarLayout)
Vue.component('BaseInput', BaseInput)
Vue.component('BaseSelect', BaseSelect)
Vue.component('BaseRow', BaseRow)
Vue.component('BaseNotification', BaseNotification)
Vue.component('AddressAutocomplete', AddressAutocomplete)
Vue.component('RadioYesNoInput', RadioYesNoInput)
Vue.component('UserAvatar', UserAvatar)
Vue.component('BankAvatar', BankAvatar)
Vue.component('OrganisationAvatar', OrganisationAvatar)
Vue.component('BankGuaranteeAgencyAgreement', BankGuaranteeAgencyAgreement)
Vue.component('StandardFooterApp', StandardFooterApp)
Vue.component('GenericDocumentModal', GenericDocumentModal)
Vue.component('FSGDocumentModal', FSGDocumentModal)

// Set up form validation
registerValidators()
setInteractionMode('eager')

getConfig<AppConfig>(`/${process.env.VUE_APP_CONFIG_FILE}`).then(data => {
  store.commit(SET_CONFIG, data)

  Sentry.init({
    dsn: 'https://143ddf2dd97a4572809c24fe11ab24f8@o341662.ingest.sentry.io/2692180',
    integrations: [new VueIntegration({ Vue, attachProps: true })],
    release: process.env.VUE_APP_RELEASE,
    enabled: process.env.VUE_APP_RELEASE !== 'local',
    environment: data.environment.name
  })

  if (data.mixPanel.enabled) {
    MixPanel.init(data.mixPanel.projectToken, { debug: process.env.VUE_APP_RELEASE === 'local' })
  }

  Vue.use(
    VueGtag,
    {
      config: {
        id: data.google.analyticsId,
        enabled: !isEmpty(data.google.analyticsId)
      }
    },
    router
  )
  if (data.reCaptcha.enabled) {
    Vue.use(VueReCaptcha, { siteKey: data.reCaptcha.siteKey })
  }
  const stripeOptions = {
    pk: data.stripe.publishableKey,
    stripeAccount: data.stripe.stripeAccount,
    apiVersion: data.stripe.apiVersion,
    locale: data.stripe.locale
  }

  Vue.use(VueGtm, {
    id: data.google.gtmId,
    queryParams: {
      gtm_auth: data.google.gtmAuth,
      gtm_preview: 'env-4',
      gtm_cookies_win: 'x'
    },
    enabled: data.google.gtmIsEnabled,
    debug: data.google.gtmIsDebugMode,
    loadScript: true,
    vueRouter: router,
    trackOnNextTick: false
  })

  Vue.use(StripePlugin, stripeOptions)
  new Vue({
    router,
    store,
    render: h => h(App),
    apolloProvider: vueApolloClient()
  }).$mount('#app')
})
