import { fetchUserGatewayFeatures, fetchUserState } from '@/graphql'
import { GatewayFeatures, Organisation, Profile, TableSort } from '@/graphql/types'
import { RootState } from '@/store'
import { ModalProgrammatic } from 'buefy'
import { BModalComponent, BModalConfig } from 'buefy/types/components'
import debounce from 'lodash/debounce'
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'

// Actions
export const RELOAD_ORGANISATIONS = 'reloadOrganisations'
export const FETCH_USER_STATE = 'fetchUserState'
export const FETCH_USER_GATEWAY_FEATURES_STATE = 'fetchUserGatewayFeatures'

export const INIT = 'init'
export const OPEN_INIT_PROMPT = 'openInitPrompt'

// Mutations
export const SET_ORGANISATION_ID = 'setOrganisationId'
const SET_ORGANISATIONS = 'setAllOrganisations'
export const SET_PROFILE = 'setProfile'
export const SET_USER_GATEWAY_FEATURES = 'setUserFeatures'
const SET_USER_ID = 'setUserId'
export const SET_TABLE_SORT_ORDER = 'setTableSortOrder'
export const SET_INIT_PROMPT = 'setInitPrompt'
export const SET_INIT_PROMPT_PROPS = 'setInitPromptProps'
export const CLOSE_INIT_PROMPT = 'closeInitPrompt'
export const SET_ORGANISATION_REQUIRED = 'organisationRequired'

// Getters
export const ORGANISATION_ID = 'organisationId'
export const ORGANISATION = 'organisation'
export const PROFILE = 'profile'
export const USER_GATEWAY_FEATURES = 'userGatewayFeatures'
export const ALL_ORGANISATIONS = 'allOrganisations'
export const OTHER_ORGANISATIONS = 'otherOrganisations'
export const TABLE_SORT_ORDER = 'tableSortOrder'
export const REQUIRES_CREATE_ORGANISATION_PROMPT = 'requiresCreateOrganisationPrompt'
export const REQUIRES_CREATE_ORGANISATION_AND_TOU_ACCEPT_PROMPT = 'requiresCreateOrganisationAndTouPrompt'
export const REQUIRES_TOU_ACCEPT_PROMPT = 'requiresTermsOfUseAcceptancePrompt'
export const INIT_PROMPT_SHOWING = 'initPromptShowing'
export const ORGANISATION_REQUIRED = 'organisationRequired'

export interface UserState {
  allOrganisations: Organisation[]
  profile: Profile | null
  userGatewayFeatures: GatewayFeatures | null
  organisationId: string | null
  userId: string | null
  tableSorts: TableSort[]
  initPromptProps: string | BModalConfig | null
  initPrompt: BModalComponent | null
  organisationRequired: boolean
}

const userIdPrefix = (userId: string, key: string) => `${userId}:${key}`

const organisationIdKey = (userId: string) => userIdPrefix(userId, 'organisation-id')

const state: UserState = {
  allOrganisations: [],
  profile: null,
  userGatewayFeatures: null,
  organisationId: null,
  userId: null,
  tableSorts: [],
  initPromptProps: null,
  initPrompt: null,
  organisationRequired: false
}

const maxProfileRetries = 30
const sleep = (delay: number) => new Promise(resolve => window.setTimeout(resolve, delay))

const actions: ActionTree<UserState, RootState> = {
  async [FETCH_USER_STATE]({ commit }) {
    const { data } = await fetchUserState()
    commit(SET_PROFILE, data.me)
    commit(SET_ORGANISATIONS, data.organisations.nodes)
    return data.me
  },
  async [FETCH_USER_GATEWAY_FEATURES_STATE]({ commit }) {
    const { data } = await fetchUserGatewayFeatures()
    commit(SET_USER_GATEWAY_FEATURES, data.features)
    return data.features
  },
  async [RELOAD_ORGANISATIONS]({ commit }) {
    const { data } = await fetchUserState()
    commit(SET_ORGANISATIONS, data.organisations.nodes)
    return data.organisations.nodes
  },
  async [INIT]({ state, getters, commit, dispatch }) {
    let counter = 0
    do {
      const promFetchUserState = dispatch(FETCH_USER_STATE)
      const promFetchGatewayFeatureState = dispatch(FETCH_USER_GATEWAY_FEATURES_STATE)
      await promFetchUserState
      await promFetchGatewayFeatureState
      if (counter != 0) {
        await sleep(1000)
      }
      counter++
    } while (!state.profile && !state.userGatewayFeatures && counter < maxProfileRetries)

    if (!state.profile) return

    const userId = state.profile.id
    commit(SET_USER_ID, userId)
    const lastOrganisationId = localStorage.getItem(organisationIdKey(userId))
    const organisations = getters[ALL_ORGANISATIONS] as Organisation[]
    const organisation = organisations.find(org => org.id === lastOrganisationId)
    if (lastOrganisationId && organisation) {
      commit(SET_ORGANISATION_ID, lastOrganisationId)
    } else if (organisations.length) {
      commit(SET_ORGANISATION_ID, organisations[0].id)
    }
    const tableSorts = localStorage.getItem('tableSortOrder')
    if (tableSorts) {
      state.tableSorts = JSON.parse(tableSorts)
    }
  },
  [OPEN_INIT_PROMPT]: debounce(async ({ state, getters, commit, dispatch }) => {
    const props = state.initPromptProps
    const openModal = state.initPrompt
    if (openModal) {
      openModal.close()
      commit(SET_INIT_PROMPT, null)
    }
    if (props) {
      const newModal = ModalProgrammatic.open(props)
      commit(SET_INIT_PROMPT, newModal)
      commit(SET_INIT_PROMPT_PROPS, null)
    }
  }, 250)
}

const mutations: MutationTree<UserState> = {
  [SET_ORGANISATIONS](state, organisations) {
    state.allOrganisations = organisations
  },
  [SET_USER_ID](state, userId) {
    state.userId = userId
  },
  [SET_PROFILE](state, profile) {
    state.profile = profile
  },
  [SET_USER_GATEWAY_FEATURES](state, userGatewayFeatures) {
    state.userGatewayFeatures = userGatewayFeatures
  },
  [SET_ORGANISATION_ID](state, organisationId) {
    localStorage.setItem(organisationIdKey(state.userId!), organisationId)
    state.organisationId = organisationId
  },
  [SET_TABLE_SORT_ORDER](state, tableSort) {
    const { tableName, tableTab, sortField, sortOrder } = tableSort
    let found = false
    state.tableSorts.forEach((ts, index) => {
      const matches = ts.tableName === tableName && ts.tableTab == tableTab
      if (matches) {
        found = true
        state.tableSorts[index] = tableSort
      }
    })
    if (!found) state.tableSorts.push(tableSort)
    localStorage.setItem('tableSortOrder', JSON.stringify(state.tableSorts))
  },
  [SET_INIT_PROMPT](state, modal) {
    state.initPrompt = modal
  },
  [SET_INIT_PROMPT_PROPS](state, props) {
    state.initPromptProps = props
  },
  [SET_ORGANISATION_REQUIRED](state, val) {
    state.organisationRequired = val
  },
  [CLOSE_INIT_PROMPT](state) {
    if (state.initPrompt) {
      state.initPrompt.close()
      state.initPrompt = null
    }
  }
}

const getters: GetterTree<UserState, RootState> = {
  [ALL_ORGANISATIONS]: ({ allOrganisations }) => allOrganisations,
  [PROFILE]: ({ profile }) => profile,
  [USER_GATEWAY_FEATURES]: ({ userGatewayFeatures }) => userGatewayFeatures,
  [ORGANISATION]: ({ allOrganisations, organisationId }) => allOrganisations.find(org => org.id === organisationId),
  [OTHER_ORGANISATIONS]: ({ allOrganisations, organisationId }) =>
    allOrganisations.filter(org => org.id !== organisationId),
  [ORGANISATION_ID]: ({ organisationId }) => organisationId,
  [TABLE_SORT_ORDER]: ({ tableSorts }) => tableSorts,
  [ORGANISATION_REQUIRED]: ({ organisationRequired }) => organisationRequired,
  [REQUIRES_CREATE_ORGANISATION_PROMPT]: ({ organisationRequired, profile, allOrganisations }) =>
    organisationRequired && profile && allOrganisations.length == 0 && !profile?.requireTermsAcceptance,
  [REQUIRES_CREATE_ORGANISATION_AND_TOU_ACCEPT_PROMPT]: ({ organisationRequired, profile, allOrganisations }) =>
    organisationRequired && profile && allOrganisations.length == 0 && profile?.requireTermsAcceptance,
  [REQUIRES_TOU_ACCEPT_PROMPT]: ({ profile }) => {
    return profile?.requireTermsAcceptance
  },
  [INIT_PROMPT_SHOWING]: ({ initPrompt, initPromptProps }) => !!initPrompt || !!initPromptProps
}

const user: Module<UserState, RootState> = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state
}

export default user
