import { DRAFT_PROCESS_TYPE_OPTIONS, DRAFT_PURPOSE_OPTIONS } from '~/constants'
import { Document, Draft, DraftProcessType, DraftPurpose } from '~/types'

export const getBGPurpose = (bg: Draft | Document): string | null => {
  if (bg.purpose == null) return null

  if (bg.purpose === DraftPurpose.OTHER) {
    return bg.specifiedOtherPurpose ?? null
  }

  return DRAFT_PURPOSE_OPTIONS.get(bg.purpose as DraftPurpose) ?? bg.purpose
}

export const processTypeString = (DraftProcessType: DraftProcessType): string | null => {
  if (DraftProcessType === null) return ''
  return DRAFT_PROCESS_TYPE_OPTIONS.get(DraftProcessType)
}
