/* eslint-disable */
import { utils, write } from 'xlsx'

// String to ArrayBuffer
const s2ab = function(s: string): any {
  if (typeof ArrayBuffer !== 'undefined') {
    let buf = new ArrayBuffer(s.length)
    let view = new Uint8Array(buf)
    for (let i = 0; i !== s.length; ++i) {
      view[i] = s.charCodeAt(i) & 0xff
    }
    return buf
  } else {
    let buf = new Array(s.length)
    for (let i = 0; i !== s.length; ++i) {
      buf[i] = s.charCodeAt(i) & 0xff
    }
    return buf
  }
}

export const convertToXlsx = function(format: 'xlsx' | 'xls' | 'csv', sheets: [{ name: string; from: any[] }]) {
  let workbook: { SheetNames: string[]; Sheets: any } = {
    SheetNames: [],
    Sheets: {}
  }

  if (!format) {
    throw new Error("'format' option must be defined")
  }
  if (format === 'csv' && sheets.length > 1) {
    throw new Error("'csv' format only supports one sheet")
  }

  sheets.forEach(function(sheetConf, index: any) {
    const name = sheetConf.name
    if (!name) {
      throw new Error('Sheet ' + index + ' must have the property "name".')
    }

    // Create sheet
    workbook.SheetNames.push(name as never)
    let worksheet = utils.aoa_to_sheet(sheetConf.from, { sheet: name } as any)
    workbook.Sheets[name] = worksheet
  })

  const wbOut = write(workbook, { bookType: format, bookSST: true, type: 'binary' })
  try {
    return new Blob([s2ab(wbOut)], { type: 'application/octet-stream' })
  } catch (e) {
    throw new Error('Error converting to ' + format + '. ' + e)
  }
}
