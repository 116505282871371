import { SortOrder } from '../tables'
import {
  ActionRequiredDocumentMessageInput,
  CreateDraftCollaboratorRole,
  DraftContractForm,
  DraftExtendedContact,
  EntityAddressState,
  PartyEntityType,
  PartyVariant,
  UpdateDraftCreatePartyInput
} from './graphql'

export enum Industry {
  construction = 'construction',
  property = 'property',
  mining = 'mining',
  other = 'other'
}

export enum PreviewModalType {
  AMP = 'AMP_STANDARD_GUARANTEE',
  MACQUARIE = 'MACQUARIE_STANDARD_GUARANTEE',
  MACQUARIE_CUSTOM_GUARANTEE = 'MACQUARIE_CUSTOM_GUARANTEE'
}

export interface Representative {
  partyId: string
  givenName: string
  familyName: string
  emailAddress: string
  role: CreateDraftCollaboratorRole
}

export interface RepresentativeOption {
  givenName: string
  familyName: string
  emailAddress: string
}

export interface DraftCreationParty {
  id?: string
  abn?: string
  name: string
  partyVariant: PartyVariant
  address: string
  representatives?: Representative[]
  primaryContact?: DraftExtendedContact
  partyEntityType?: PartyEntityType
}

export interface DraftCreatedPartyType extends UpdateDraftCreatePartyInput {
  id: string
}

export interface RequiredDocumentMessageApprovalInput extends ActionRequiredDocumentMessageInput {
  attachment?: File | null
}

export enum PARTY_CHANGE_TYPE {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE'
}

export enum ErrorCode {
  COLLABORATOR_ALREADY_EXISTS = 'COLLABORATOR_ALREADY_EXISTS',
  MEMBER_ALREADY_EXISTS = 'MEMBER_ALREADY_EXISTS',
  VALIDATION_ERROR = 'VALIDATION_ERROR'
}

export enum FetchFacilityFilter {
  CAN_ATTACH_TO_DOCUMENTS = 'CAN_ATTACH_TO_DOCUMENTS',
  CAN_RAISE_DRAFTS = 'CAN_RAISE_DRAFTS'
}

export type RichTextPrefillOption = {
  id: string
  display: string
  content: string
}

export type EntityTypes = { [key in PartyEntityType]: boolean }

export type FeeSummaryDetails = {
  creditRating: string
  documents: [
    {
      name: string
      link?: string
    },
    {
      name: string
      link?: string
    },
    {
      name: string
      link?: string
    },
    {
      name: string
      link?: string
    }
  ]
  processingDays?: string
  limits: {
    minAmount?: number | null
    requiresExpiry?: boolean
    entityTypes?: EntityTypes
  }
  previewModal?: PreviewModalType | null
  interestRate?: number | null
  interestRatesUrl?: string | null
}

export type fieldNameToRequirementMappingOptions = {
  [key: string]: { name: string; attachment: boolean }
}

export type ContractFormRichTextPrefillOption = RichTextPrefillOption & { contractForm: DraftContractForm }

export enum RichTextPrefillResultType {
  TEMPLATE = 'TEMPLATE',
  CUSTOM = 'CUSTOM'
}

export type RichTextPrefillResult = {
  resultType: RichTextPrefillResultType
  templateId: string | null
  customContent: string | null
}

export enum DraftHistoryObjectType {
  Draft = 'Draft',
  Approval = 'Approval',
  Collaborator = 'Collaborator',
  Comment = 'Comment'
}

export interface AddressResult {
  singleLine: string
  isManualEntry: boolean
  postCode?: string
  unitNumber?: string
  streetNumber?: string
  streetName?: string
  city?: string
  state?: string
  country?: string
}
export interface EntityAddress {
  addressLine1: string
  addressLine2?: string
  addressSuburb: string
  addressPostcode: string
  addressState: EntityAddressState
}

export type IdentificationIndividualType =
  | 'director'
  | 'approver'
  | 'beneficialOwner'
  | 'accountSignatory'
  | 'foreignResident'

export type TableSort = {
  tableName: string
  tableTab: string | null
  sortField: string
  sortOrder: SortOrder | string
}
