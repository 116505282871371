import { BankUserRole, EntityType, MemberStatus } from '~/types'

export const ENTITY_TYPE_OPTIONS: Record<EntityType, string> = {
  [EntityType.CORPORATE_PARTNER]: 'Corporate Partner',
  [EntityType.REFERRAL_PARTNER]: 'Referral Partner'
}

export const MEMBER_STATUS_OPTIONS: Record<MemberStatus, string> = {
  [MemberStatus.ACTIVE]: 'Active',
  [MemberStatus.DELETED]: 'Deleted',
  [MemberStatus.INVITED]: 'Invited'
}

export const REFERRAL_PORTAL_ROLE_OPTIONS = {
  [BankUserRole.EXTERNAL_REFERRALPORTAL_ADMIN]: 'Admin',
  [BankUserRole.EXTERNAL_REFERRALPORTAL_REFERRER]: 'Referrer'
}
