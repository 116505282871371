<template>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">Import Result</p>
    </header>
    <section class="modal-card-body">
      <div v-if="importResult.status !== 'COMPLETE'">
        <div>
          <BIcon icon="alert-triangle" class="has-text-danger" />
          Error processing document, upload was unsuccessful
        </div>
        <br />
        <BNotification v-if="importResult.errors.length" type="is-danger" :closable="false">
          <div v-for="(error, key) in importResult.errors" :key="key">{{ error }}</div>
        </BNotification>
      </div>

      <div v-else>
        <div>
          <BIcon icon="check-circle" class="has-text-success padding-right-1" />
          <b> {{ importResult.recordsProcessed }}</b> records imported successfully
        </div>
        <div>
          <BIcon icon="alert-triangle" class="has-text-warning padding-right-1" />
          <b> {{ importResult.recordsFailed }}</b> records failed to import
        </div>
        <br />
        <BNotification v-if="importResult.items.length" type="is-warning" :closable="false">
          <div v-for="(item, key) in importResult.items" :key="key">
            Row {{ item.rowNumber }}: {{ item.errors.join(', ') }}
          </div>
        </BNotification>
      </div>
    </section>

    <footer class="modal-card-foot">
      <BButton type="is-info" @click="$emit('close')"> Ok </BButton>
    </footer>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class DocumentUploadResultsModal extends Vue {
  @Prop({ required: true }) importResult: {}
}
</script>

<style lang="scss" scoped></style>
