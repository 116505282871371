import { addDays, addMonths, differenceInDays, min, parseISO } from 'date-fns'
import { DocumentCosts, RecurringFeeType } from '~/types/graphql/'

export interface CostForecastable {
  establishedAt?: string
  closedAt?: string
  actionDate?: string
  amount: number
  costs?: {
    setUpCost: number
    recurringFixedCost?: number | null
    recurringCostFrequency: number
    recurringPercentageCost?: number | null
    recurringMinimumAnnualCost?: number | null
    adjustedCost?: boolean
    recurringFeeType: RecurringFeeType
  }
}

interface Interest {
  establishedAt?: string
  closedAt?: string
  amount: number
  costs?: {
    recurringFixedCost?: number | null
    recurringCostFrequency: number
    recurringPercentageCost?: number | null
  }
  interestStartDate?: string
  interestEndDate?: string
}

interface Period {
  startDate: Date
  endDate: Date
}

interface ForecastCostResults {
  total: number
  periods: Period[]
  includesSetUpCost: boolean
}

export const calculatePeriodStartDate = (
  documentStartDate: Date,
  forecastStartDate: Date | undefined,
  periodLengthMonths: number
): Date => {
  if (!forecastStartDate || documentStartDate > forecastStartDate) {
    return documentStartDate
  }
  if (!periodLengthMonths) {
    return forecastStartDate
  }
  let periodStartDate = documentStartDate
  while (periodStartDate < forecastStartDate) {
    periodStartDate = addMonths(periodStartDate, periodLengthMonths)
  }
  return periodStartDate
}

export const calculatePeriodEndDate = (documentEndDate: Date, forecastEndDate: Date | undefined): Date => {
  return forecastEndDate ? min([forecastEndDate, documentEndDate]) : documentEndDate
}

export const calculatePeriods = (
  periodLengthMonths: number,
  startDate: Date,
  endDate: Date,
  calculateInAdvance: boolean = true
): Period[] => {
  let periodStartDate = startDate
  let periodEndDate = min([addDays(addMonths(periodStartDate, periodLengthMonths), -1), endDate])
  const periods: Period[] = []
  while ((calculateInAdvance ? periodStartDate : periodEndDate) < endDate) {
    periods.push({
      startDate: periodStartDate,
      endDate: periodEndDate
    })
    periodStartDate = addMonths(periodStartDate, periodLengthMonths)
    periodEndDate = min([addDays(addMonths(periodStartDate, periodLengthMonths), -1), endDate])
  }
  return periods
}

export const forecastCosts = (
  item: CostForecastable,
  forecastStartDate?: Date | undefined,
  forecastEndDate?: Date | undefined
): ForecastCostResults | null => {
  const { establishedAt, actionDate, closedAt, costs } = item

  // we require costs to be provided
  if (!costs) {
    return null
  }

  // we require the establishment date to be provided to determine the document start date
  if (!establishedAt) {
    return null
  }

  // we require the action date (expected return or expiry date) or the closed date to determine the document end date if no end date is given
  if (!actionDate && !closedAt && !forecastEndDate) {
    return null
  }

  // deconstruct required fields
  var {
    setUpCost,
    recurringFixedCost,
    recurringCostFrequency,
    recurringPercentageCost,
    adjustedCost,
    recurringFeeType,
    recurringMinimumAnnualCost
  } = costs

  // determine the starting period (i.e. the date the first charge will be applied)
  const documentStartDate = parseISO(establishedAt)
  const startDate = calculatePeriodStartDate(documentStartDate, forecastStartDate, recurringCostFrequency)

  // determine the end date of the forecast period (i.e. the date up to which the last charge will cover)
  const documentEndDate = closedAt || actionDate ? parseISO(closedAt ? closedAt : actionDate!) : undefined
  const endDate = documentEndDate ? calculatePeriodEndDate(documentEndDate, forecastEndDate) : forecastEndDate!

  const includesDocumentStartDate = documentStartDate >= startDate && documentStartDate < endDate
  const initialSetupFee = includesDocumentStartDate ? setUpCost : 0

  recurringFixedCost = recurringFixedCost || 0
  recurringPercentageCost = recurringPercentageCost || 0

  if (!recurringCostFrequency) {
    let oneTimeFee = 0
    if (includesDocumentStartDate) {
      if (recurringFeeType === RecurringFeeType.FIXED_FEE) oneTimeFee = recurringFixedCost
      else {
        const coveredPeriods = calculatePeriods(12, startDate, documentEndDate || endDate)
        recurringFixedCost = calculateAnnualCostFromFrequency(item.amount, recurringPercentageCost, 12)
        oneTimeFee = recurringFixedCost * coveredPeriods.length
      }
    }
    return {
      total: initialSetupFee + oneTimeFee,
      periods: [],
      includesSetUpCost: includesDocumentStartDate
    }
  }

  // Calculate FixedCost For Annual fees
  if (costs.recurringFeeType != RecurringFeeType.FIXED_FEE) {
    const annualCost = calculateAnnualCostFromFrequency(item.amount, recurringPercentageCost, recurringCostFrequency)
    const frequency = 12 / recurringCostFrequency
    recurringFixedCost = Math.round((annualCost / frequency) * 100) / 100
    if (recurringMinimumAnnualCost && recurringMinimumAnnualCost > recurringFixedCost)
      recurringFixedCost = (Math.ceil((recurringMinimumAnnualCost / frequency) * 100) * frequency) / 100
  }

  const coveredPeriods = calculatePeriods(recurringCostFrequency, startDate, endDate)
  var total = coveredPeriods.length * recurringFixedCost + initialSetupFee

  // is document cost adjusted ( calculate pro rata of the last period  )
  if (adjustedCost && coveredPeriods.length) {
    const lastCycle = coveredPeriods.pop()
    if (lastCycle) {
      const proRataCost = calculateAmountPartiallyAccrued(
        lastCycle.startDate,
        lastCycle.endDate,
        recurringCostFrequency,
        recurringFixedCost
      )
      const returnedCost = recurringFixedCost - proRataCost
      total -= returnedCost
    }
  }

  return {
    total: total,
    periods: coveredPeriods,
    includesSetUpCost: includesDocumentStartDate
  }
}

export const calculateAnnualCostFromFrequency = (amount: number, percent: number, frequency: number): number => {
  if (!frequency || !amount || !percent) return 0
  frequency = 12 / frequency
  return (Math.round((amount / frequency) * percent) / 100) * frequency
}

export const calculateAnnualCost = (
  costs: DocumentCosts | undefined,
  amount: number | undefined | null
): number | null => {
  if (!costs || !amount) return null
  var annualCost = 0
  if (costs.recurringCostFrequency > 0) {
    if (
      costs.recurringFeeType == RecurringFeeType.ANNUAL_PERCENTAGE &&
      costs.recurringPercentageCost &&
      costs.recurringPercentageCost > 0
    )
      annualCost = calculateAnnualCostFromFrequency(amount, costs.recurringPercentageCost, costs.recurringCostFrequency)
    else if (
      costs.recurringFeeType == RecurringFeeType.FIXED_FEE &&
      costs.recurringFixedCost &&
      costs.recurringFixedCost > 0
    ) {
      annualCost = (costs.recurringFixedCost * 12) / costs.recurringCostFrequency
    }
  }
  if (costs.recurringMinimumAnnualCost && costs.recurringMinimumAnnualCost > annualCost) {
    var frequency = 12 / costs.recurringCostFrequency
    // used to calibrate annualCost as a sum of recurringFixedFee (minFee / recurrenceFrequency) adjusted to 2 decimals
    annualCost = (Math.ceil((costs.recurringMinimumAnnualCost / frequency) * 100) * frequency) / 100
  }

  return annualCost
}

export const estimateInterestAccrued = (item: Interest) => {
  const { costs, interestStartDate, interestEndDate } = item

  // we require costs to be provided
  if (!costs) {
    return null
  }

  // we require the establishment date or interestStartDate to be provided to determine the document start date
  if (!interestEndDate || !interestStartDate) {
    return null
  }

  // destructure required fields
  var { recurringCostFrequency, recurringFixedCost, recurringPercentageCost } = costs

  const startDate = parseISO(interestStartDate)

  // determine the end date of the forecast period (i.e. the date up to which the last charge will cover)
  const endDate = parseISO(interestEndDate)

  if (startDate > endDate) return null

  if (!recurringCostFrequency) {
    const annualCost = (item.amount * (recurringPercentageCost || 0)) / 100
    const coveredPeriods = calculatePeriods(12, startDate, endDate, false)
    const total = coveredPeriods.length * annualCost

    const lastBillingDay = coveredPeriods.pop()
    const nextBillingStartDay = lastBillingDay ? addDays(lastBillingDay.endDate, 1) : startDate

    const accruedUnpaid = calculateAmountPartiallyAccrued(nextBillingStartDay, endDate, 12, annualCost)
    return {
      total: 0,
      accruedUnpaid: total + accruedUnpaid,
      periods: []
    }
  }

  recurringFixedCost = recurringFixedCost || 0

  const coveredPeriods = calculatePeriods(recurringCostFrequency, startDate, endDate, false)
  const total = coveredPeriods.length * recurringFixedCost

  // calculate amount accrued and unpaid
  const lastBillingDay = coveredPeriods.pop()
  const nextBillingStartDay = lastBillingDay ? addDays(lastBillingDay.endDate, 1) : startDate
  const accruedUnpaid = calculateAmountPartiallyAccrued(
    nextBillingStartDay,
    endDate,
    recurringCostFrequency,
    recurringFixedCost
  )

  return {
    total,
    accruedUnpaid,
    periods: coveredPeriods
  }
}

export const calculateAmountPartiallyAccrued = (
  startDate: Date,
  endDate: Date,
  periodLengthMonths: number,
  amountFullPeriod: number
) => {
  const daysElapsed = differenceInDays(endDate, startDate)
  const nextPaymentDate = addMonths(startDate, periodLengthMonths)
  const daysInCycle = differenceInDays(nextPaymentDate, startDate)
  return (daysElapsed / daysInCycle) * amountFullPeriod
}
