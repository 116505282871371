<template>
  <ValidationObserver v-slot="{ handleSubmit }" slim>
    <form @submit.prevent="handleSubmit(createOrganisation)">
      <div class="modal-card" :data-cy-tag="cyTag">
        <header class="modal-card-head">
          <p class="modal-card-title">Create Organisation</p>
        </header>
        <section class="modal-card-body">
          <BaseInput
            v-model.trim="orgName"
            rules="required|attribute"
            label="Organisation name"
            placeholder="Organisation name"
            maxlength="255"
          />
          <BaseInput v-model="abn" label="ABN/ACN" placeholder="ABN or ACN" rules="abnOrAcn" maxlength="14" />
          <AddressAutocomplete
            v-model="address"
            label="Business Address"
            placeholder="Business Address"
            rules="address"
            maxlength="255"
          />
          <BaseSelect v-model="industryType" label="Industry" placeholder="Select Industry" rules="required" expanded>
            <option v-for="[key, value] in industryOptions" :key="key" :value="key">{{ value }}</option>
          </BaseSelect>

          <section v-if="isReferredSectionVisible">
            <RadioYesNoInput v-model="isReferredToAssuro" label="Were you referred to Assuro by a 3rd party?" />
            <ValidationProvider v-if="isReferredToAssuro" v-slot="{ errors }" rules="required" slim>
              <BField label="Partner" :type="{ 'is-danger': errors[0] }" :message="errors">
                <BAutocomplete
                  v-model="searchByName"
                  placeholder="Select Partner"
                  icon="search"
                  icon-right="chevron-down"
                  field="name"
                  rules="required"
                  data-cy-tag="partner-input"
                  open-on-focus
                  clearable
                  :data="filteredEntities"
                  :loading="isLoading"
                  @select="option => (selectedEntity = option)"
                >
                  <template #empty>No results found</template>
                  <template slot-scope="props">
                    {{ props.option.name }}
                  </template>
                </BAutocomplete>
              </BField>
            </ValidationProvider>
          </section>

          <section v-if="publicChannelPartners?.length" class="mt-4">
            <h4 class="title is-4">Trade Organisations</h4>
            <div class="columns is-multiline">
              <div v-for="{ id, name } in publicChannelPartners" :key="id" class="column is-6">
                <BCheckbox v-model="currentChannelPartners" :native-value="id" :data-cy-tag="`channel-partner-${name}`">
                  {{ name }}
                </BCheckbox>
              </div>
            </div>
          </section>

          <div v-if="requireTermsAcceptance" class="pt-2">
            <ValidationProvider name="otherTerms" slim>
              <BField data-cy-tag="termsAcceptance">
                <BCheckbox v-model="termsAcceptance">
                  I agree to Assuro's
                  <RouterLink :to="{ name: 'terms' }" target="_blank">Terms and Conditions</RouterLink>.
                </BCheckbox>
              </BField>
            </ValidationProvider>
            <ValidationProvider v-slot="{ errors }" rules="acceptTerms:@otherTerms" tag="div">
              <BField :type="{ 'is-danger': errors[0] }" :message="errors" data-cy-tag="privacyAcceptance">
                <BCheckbox v-model="privacyAcceptance">
                  I agree to Assuro's
                  <a href="https://assuro.com.au/privacy" target="_blank"> Privacy Policy </a> -
                  <a href="https://assuro.com.au/complaintspolicy" target="_blank"> Complaints Policy </a>
                </BCheckbox>
              </BField>
            </ValidationProvider>
          </div>
          <BNotification :active="isError" type="is-danger" :closable="false"
            >Sorry, we were unable to create your organisation.</BNotification
          >
        </section>
        <footer class="modal-card-foot">
          <BButton v-if="canCancel" @click="$emit('close')"> Cancel </BButton>
          <BButton type="is-primary" native-type="submit"> Create Organisation </BButton>
        </footer>
        <BLoading :is-full-page="false" :active.sync="isLoading" />
      </div>
    </form>
  </ValidationObserver>
</template>

<script lang="ts">
import { INDUSTRY_OPTIONS, ORGANISATION_VARIANT_OPTIONS } from '@/constants'
import { createOrganisation, profileTermsAccepted, queries } from '@/graphql'
import { ChannelPartners, CreateOrganisationInput, Entity, Profile, SortEnumType } from '@/graphql/types'
import { ALL_ORGANISATIONS, CLOSE_INIT_PROMPT, FETCH_USER_STATE, PROFILE, SET_ORGANISATION_ID, USER_MOD } from '@/store'
import { namespaceWrapper, trackEvent } from '@/utils'
import { isEmpty } from 'lodash'
import get from 'lodash/get'
import { v4 as uuid } from 'uuid'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { INT32_MAX } from '~/constants/numbers'

const user = namespaceWrapper(USER_MOD)

@Component({
  components: { ValidationProvider, ValidationObserver }
})
export default class OrganisationCreate extends Vue {
  @Prop({ required: true }) canCancel: boolean
  @Prop({ required: true }) requireTermsAcceptance: boolean

  industryOptions = INDUSTRY_OPTIONS
  organisationOptions = ORGANISATION_VARIANT_OPTIONS

  orgName: string = ''
  abn: string | null = null
  industryType: string | null = null
  address: string = ''
  termsAcceptance: boolean = false
  privacyAcceptance: boolean = false
  publicChannelPartners: ChannelPartners[] = []
  currentChannelPartners: string[] = []
  isError: boolean = false
  isLoading: boolean = false
  isReferredToAssuro: boolean = false
  entityList: Entity[] = []
  searchByName: string = ''
  selectedEntity: Entity | null = null

  get filteredEntities() {
    const searchValue = this.searchByName.toLowerCase()
    return this.entityList.filter(option => {
      const searchOption = option.name.toLowerCase()
      return searchOption.indexOf(searchValue) >= 0
    })
  }

  get hasOrganisation(): boolean {
    return !isEmpty(this.$store.getters[user(ALL_ORGANISATIONS)])
  }

  get userProfile(): Profile {
    return this.$store.getters[user(PROFILE)]
  }

  get hasUserBeenReferred(): boolean {
    return !!this.userProfile.referrerCode || !!this.userProfile.referralPortalReferrerMemberCode
  }

  get isReferredSectionVisible(): boolean {
    return !this.hasOrganisation && !this.hasUserBeenReferred
  }

  get cyTag(): string {
    if (this.requireTermsAcceptance) {
      return 'organisation-create-and-terms-acceptance'
    }
    return 'organisation-create'
  }

  async mounted() {
    await this.getPublicChannelPartners()
    await this.fetchPartners()
    if (
      this.userProfile &&
      this.userProfile.industry &&
      this.industryType === null &&
      this.industryOptions.has(this.userProfile.industry)
    ) {
      this.industryType = this.userProfile.industry
    }
  }

  async getPublicChannelPartners() {
    await this.$apollo
      .query({
        query: queries.CHANNEL_PARTNERS_ACTIVE_QUERY,
        variables: {
          where: {
            isPublic: {
              eq: true
            }
          }
        }
      })
      .then(response => (this.publicChannelPartners = get(response, 'data.channelPartners.nodes')))
      .catch((error: any) =>
        this.$buefy.toast.open({
          message: error.message,
          type: 'is-danger'
        })
      )
  }

  close() {
    this.$emit('close')
    this.$store.commit(user(CLOSE_INIT_PROMPT))
  }

  async createOrganisation() {
    this.isLoading = true
    this.isError = false
    const payload: CreateOrganisationInput = {
      clientMutationId: uuid(),
      abn: !this.abn ? undefined : this.abn,
      address: this.address,
      industryType: this.industryType ? this.industryType : undefined,
      name: this.orgName,
      referralPortalReferralEntityId: this.selectedEntity ? this.selectedEntity.id : undefined,
      channelPartnerIds: this.currentChannelPartners
    }
    if (this.requireTermsAcceptance) {
      await profileTermsAccepted({
        clientMutationId: uuid()
      }).catch(() => (this.isError = true))
    }

    createOrganisation(payload)
      .then(
        ({
          data: {
            createOrganisation: { id }
          }
        }) => {
          trackEvent('ORGANISATION_CREATED', {
            organisationId: id,
            organisationName: this.orgName
          })

          this.$store.dispatch(user(FETCH_USER_STATE)).then(() => this.$store.commit(user(SET_ORGANISATION_ID), id))
          this.close()
          this.$buefy.toast.open({
            message: 'Organisation successfully created'
          })
        }
      )
      .catch(() => (this.isError = true))
      .finally(() => (this.isLoading = false))
  }

  async fetchPartners() {
    this.isLoading = true
    const variables = {
      order: [{ entityType: SortEnumType.ASC, name: SortEnumType.ASC }],
      first: INT32_MAX // set pagesize - int.maxValue
    }
    await this.$apollo
      .query({
        query: queries.GET_REFERRAL_PORTAL_ENTITIES,
        variables
      })
      .then(({ data: { referralPortalEntitiesForClientPortal } }) => {
        this.entityList = referralPortalEntitiesForClientPortal.nodes
      })
      .catch(error => this.$buefy.toast.open({ message: error.message, type: 'is-danger' }))
      .finally(() => (this.isLoading = false))
  }
}
</script>
<style lang="scss" scoped></style>
