import {
  configure,
  confirmSignUp,
  currentSession,
  currentUser,
  forgotPassword,
  forgotPasswordSubmit,
  resendConfirmationCode,
  signIn,
  signOut,
  signUp,
  startOauthSignIn
} from '@/auth'
import { APP_CONFIG, RootState, USER_MOD } from '@/store'
import { INIT as INIT_USER_STATE } from '@/store/user'
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth'
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'

// Actions
export const FETCH_SESSION = 'fetchSession'
export const FETCH_CURRENT_USER = 'fetchCurrentUser'
export const SIGN_UP = 'signUp'
export const ID_TOKEN = 'idToken'
const CONFIGURE_CLIENT = 'configureClient'

// Mutations
const SET_USER = 'setUser'
const SET_SESSION = 'setSession'

// Getters
export const IS_LOGGED_IN = 'isLoggedIn'

interface CognitoState {
  session: any
  user: any
}

const state: CognitoState = {
  user: null,
  session: null
}

const actions: ActionTree<CognitoState, RootState> = {
  async init({ dispatch }) {
    return await dispatch(CONFIGURE_CLIENT)
      .then(() => dispatch(FETCH_SESSION))
      .then(() => dispatch(FETCH_CURRENT_USER))
      .then(() => dispatch(`${USER_MOD}/${INIT_USER_STATE}`, {}, { root: true }))
      .catch(() => {})
  },
  async [CONFIGURE_CLIENT]({ rootGetters }) {
    const config = await rootGetters[APP_CONFIG]
    configure(config)
  },
  async [FETCH_SESSION]({ commit }) {
    return await currentSession().then(session => commit(SET_SESSION, session))
  },
  async [FETCH_CURRENT_USER]({ commit }) {
    return await currentUser().then(user => commit(SET_USER, user))
  },
  async [SIGN_UP](
    { commit },
    payload: { email: string; password: string; reCaptchaToken?: string; attributes?: object }
  ) {
    const { email, password, reCaptchaToken, attributes = {} } = payload
    return await signUp(email, password, reCaptchaToken, attributes).then(err => err)
  },
  async signIn({ state, commit, dispatch }, payload: { email: string; password: string; reCaptchaToken?: string }) {
    const { email, password, reCaptchaToken } = payload
    return await signIn(email, password, reCaptchaToken)
      .then((user: any) => commit(SET_USER, user))
      .then(() => dispatch(FETCH_SESSION))
      .then(() => dispatch(`${USER_MOD}/${INIT_USER_STATE}`, {}, { root: true }))
  },
  async oauthSignIn(data, payload: { provider: CognitoHostedUIIdentityProvider }) {
    const { provider } = payload
    return await startOauthSignIn(provider)
  },
  async signOut({ commit }) {
    return await signOut().then(() => {
      commit(SET_SESSION, null)
      commit(SET_USER, null)
    })
  },
  async forgotPassword({ commit }, payload: { email: string }) {
    const { email } = payload
    return await forgotPassword(email)
  },
  async forgotPasswordSubmit({ commit }, payload: { username: string; code: string; newPassword: string }) {
    const { username, code, newPassword } = payload
    return await forgotPasswordSubmit(username, code, newPassword)
  },
  async confirmSignUp({ commit }, payload: { username: string; code: string }) {
    const { username, code } = payload
    return await confirmSignUp(username, code)
  },
  async resendConfirmationCode({ commit }, payload: { username: string }) {
    const { username } = payload
    return await resendConfirmationCode(username)
  },

  async [ID_TOKEN]({ state, dispatch }) {
    await dispatch('fetchSession')
    const {
      session: {
        idToken: { jwtToken }
      }
    } = state
    return jwtToken
  }
}

const getters: GetterTree<CognitoState, RootState> = {
  [IS_LOGGED_IN]({ session }) {
    return !!session
  }
}

const mutations: MutationTree<CognitoState> = {
  [SET_USER](state, user) {
    state.user = user
  },
  setSession(state, session) {
    state.session = session
  }
}

const cognito: Module<CognitoState, RootState> = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state
}

export default cognito
