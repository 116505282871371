// BondDraftCreateToolTips Methods are define according to the fields order
export const tooltips = {
  purposeTooltip: 'Select the purpose of bond required for this surety.',
  amountTooltip: ' Refers to the value of the bond being established ',
  periodFromDateToolTip: 'The date the bond commence from.',
  periodToDateToolTip: 'The date the bond expected to be returned.',
  ExpiryDateToolTip: 'Select a date you want the bond to expire. This is not mandatory.',
  hasBondWordingToolTip:
    'Some contracts will require specific custom bond wording, whereas others will adopt existing set wording, for example: AS2124 or AS4000. If you require specific custom bond wording then select "yes". ',
  bankFrontedGuaranteeToolTip:
    'If the beneficiary of the proposed bond will not accept a surety bond a bank fronted bond can be provided as an alternative.',
  fullDescriptionOfContractToolTip:
    'Insert the details of what the bond relates to, for example, the project details , scope of works and bond purpose.',
  locationOfContractToolTip: 'The physical location where the contract will be performed.',
  totalValueOfContractToolTip: 'The total value of the contract for which the bond is required.',
  contractNumberToolTip: 'The contract number for which the bond is required.',
  contractStartDateToolTip: 'The date the contract starts.',
  contractCompletionDateToolTip: 'The completion date for the contract as detailed in the contract.',
  practicalCompletionDateToolTip: 'The practical completion date as detailed in the contract.',
  finalCompletionDateToolTip:
    'Refers to the date in the contract that your scope of works are required to be completed.',
  maintenanceYearsToolTip:
    'Number of years latent defects to be covered post completion of the project as detailed in the contract.',
  maintenanceMonthsToolTip:
    'Number of months latent defects required to be covered post the project completion date. Note, this is the number of months in addition to the years already specified. for example, if the total period latent defects must be covered is 30 months, then the number of months would be "6" and the number of years would be "2".',
  issuerNameToolTip: 'The name of the organisation that the bond is issuing.',
  issuerAbnAcnToolTip: 'The ABN or ACN of the organisation that the bond is issuing.',
  issuerAddressToolTip: 'What is the registered address of the organisation that the bond is issuing.',
  beneficiaryNameToolTip: 'The name of the organisation that the bond needs to be made out to.',
  beneficiaryAbnAcnToolTip: 'The ABN or ACN of the organisation that the bond needs to be made out to.',
  beneficiaryAddressToolTip:
    'What is the registered address of the organisation that the bond needs to be made out to?',
  beneficiaryContactDetailsToolTip:
    'The name, contact number and email address of the key contact point at the organisation which this bond is being made out to.',
  clientsRepresentativeToolTip:
    'Refers to the key point of contact for the contract at your organisation. This is typically the project manager for the project.',
  headContractorOrSubcontractorToolTip:
    'Confirm if you are the head contractor or a subcontractor as detailed in the contract',
  forceMjoureToolTip:
    'Does the contract expose you to risks related to force majeure or does the contract exclude you from being liable to risks related to force majeure?',
  nuclearRisksToolTip: 'Does the contract remove your organisation from being exposed to any nuclear risks ?'
}
