<template>
  <div id="app">
    <transition name="fade">
      <RouterView />
    </transition>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator'
import OrganisationCreate from '@/components/OrganisationCreate.vue'
import TermsOfUseAcceptanceModal from '@/components/TermsOfUseAcceptanceModal.vue'
import {
  INIT_PROMPT_SHOWING,
  OPEN_INIT_PROMPT,
  ORGANISATION_REQUIRED,
  PROFILE,
  REQUIRES_CREATE_ORGANISATION_AND_TOU_ACCEPT_PROMPT,
  REQUIRES_CREATE_ORGANISATION_PROMPT,
  REQUIRES_TOU_ACCEPT_PROMPT,
  SET_INIT_PROMPT_PROPS,
  USER_GATEWAY_FEATURES,
  USER_MOD
} from '@/store'
import { namespaceWrapper } from '@/utils'
import { BModalConfig } from 'buefy/types/components'

const user = namespaceWrapper(USER_MOD)

@Component
export default class App extends Vue {
  get profile() {
    return this.$store.getters[user(PROFILE)]
  }
  get userFeatures() {
    return this.$store.getters[user(USER_GATEWAY_FEATURES)]
  }

  get shouldShowCreateOrganisationPrompt() {
    return this.$store.getters[user(REQUIRES_CREATE_ORGANISATION_PROMPT)]
  }

  get shouldShowCombinedPrompt() {
    return this.$store.getters[user(REQUIRES_CREATE_ORGANISATION_AND_TOU_ACCEPT_PROMPT)]
  }

  get shouldShowTermsOfUsePrompt() {
    return this.$store.getters[user(REQUIRES_TOU_ACCEPT_PROMPT)]
  }

  get userInitPromptShowing() {
    return this.$store.getters[user(INIT_PROMPT_SHOWING)]
  }

  get organisationRequired() {
    return this.$store.getters[user(ORGANISATION_REQUIRED)]
  }

  get routePath() {
    return this.$route.path
  }

  get nextPath() {
    return localStorage.getItem('next-path') && localStorage.getItem('next-path') !== this.routePath
      ? localStorage.getItem('next-path')
      : undefined
  }

  mounted() {
    this.ensureRequiredData()
  }

  @Watch('userFeatures')
  @Watch('profile')
  @Watch('organisationRequired')
  @Watch('routePath')
  ensureRequiredData() {
    if (!this.profile || !this.userFeatures) return

    if (this.nextPath) {
      if (this.shouldShowTermsOfUsePrompt) {
        // prompt user to accept terms of use only
        this.createTermsOfUseAcceptanceModal()
      } else if (!this.userInitPromptShowing) {
        // follow the redirect if there is no other prompt showing
        // This is chained so that the path is only removed from local storage
        //  after successful navigation to the path. This causes problems otherwise.
        if (this.nextPath !== this.$route.fullPath)
          this.$router.push({ path: this.nextPath }).then(() => localStorage.removeItem('next-path'))
        else localStorage.removeItem('next-path')
      }
    } else if (this.shouldShowCombinedPrompt) {
      // prompt for organisation creation and terms acceptance
      this.createOrganisationModal({
        canCancel: false,
        requireTermsAcceptance: true
      })
    } else if (this.shouldShowCreateOrganisationPrompt) {
      // prompt for just organisation creation
      this.createOrganisationModal({
        canCancel: false,
        requireTermsAcceptance: false
      })
    } else if (this.shouldShowTermsOfUsePrompt) {
      // prompt user to accept terms of use only
      this.createTermsOfUseAcceptanceModal()
    }

    if (
      this.userInitPromptShowing &&
      !this.shouldShowTermsOfUsePrompt &&
      !this.shouldShowCreateOrganisationPrompt &&
      !this.shouldShowCombinedPrompt
    ) {
      // clear any current prompts if they are no longer needed
      this.setInitPrompt(null)
    }
  }

  setInitPrompt(props: string | BModalConfig | null) {
    this.$store.commit(user(SET_INIT_PROMPT_PROPS), props)
    this.$store.dispatch(user(OPEN_INIT_PROMPT))
  }

  createTermsOfUseAcceptanceModal() {
    this.setInitPrompt({
      canCancel: false,
      parent: this,
      component: TermsOfUseAcceptanceModal,
      trapFocus: true,
      hasModalCard: true
    })
  }

  createOrganisationModal(options?: { canCancel: boolean; requireTermsAcceptance: boolean }) {
    if (!options) {
      options = {
        canCancel: true,
        requireTermsAcceptance: false
      }
    }

    this.setInitPrompt({
      ...options,
      parent: this,
      component: OrganisationCreate,
      trapFocus: true,
      hasModalCard: true,
      props: options
    })
  }
}
</script>
<style lang="scss" src="@/styles/index.scss" />
