const getNumberFormatter = (options: Intl.NumberFormatOptions, locale: string = 'en-AU') => {
  return new Intl.NumberFormat(locale, options)
}

export const toCurrency = (input: number, includeSymbol: boolean = false, includeCents: boolean = true) => {
  if (typeof input !== 'number') {
    return ''
  }

  const formatter = getNumberFormatter({
    style: includeSymbol ? 'currency' : 'decimal',
    currency: 'AUD',
    minimumFractionDigits: includeCents ? 2 : 0,
    maximumFractionDigits: includeCents ? 2 : 0
  })

  return formatter.format(input)
}

export const toCurrencyWithoutCents = (input: number, includeSymbol: boolean = false) =>
  toCurrency(input, includeSymbol, false)

export const numberFormatter = (number: number) => {
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' }
  ]
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/
  var item = lookup
    .slice()
    .reverse()
    .find(function(item) {
      return number >= item.value
    })
  return item ? (number / item.value).toFixed(0).replace(rx, '$1') + item.symbol : '0'
}

export const numberToWords = (s: any) => {
  var th_val = ['', 'thousand', 'million', 'billion', 'trillion']

  var dg_val = ['zero', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine']
  var tn_val = [
    'ten',
    'eleven',
    'twelve',
    'thirteen',
    'fourteen',
    'fifteen',
    'sixteen',
    'seventeen',
    'eighteen',
    'nineteen'
  ]
  var tw_val = ['twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety']
  s = s.toString()
  // @ts-ignore
  s = s.replace(/[, ]/g, '')
  if (s != parseFloat(s)) return 'not a number '
  var x_val = s.indexOf('.')
  if (x_val == -1) x_val = s.length
  if (x_val > 15) return 'too big'
  var n_val = s.split('')
  var str_val = ''
  var sk_val = 0
  for (var i: number = 0; i < x_val; i++) {
    if ((x_val - i) % 3 == 2) {
      if (n_val[i] == '1') {
        str_val += tn_val[Number(n_val[i + 1])] + ' '
        i++
        sk_val = 1
      } else if (n_val[i] != 0) {
        str_val += tw_val[n_val[i] - 2] + ' '
        sk_val = 1
      }
    } else if (n_val[i] != 0) {
      str_val += dg_val[n_val[i]] + ' '
      if ((x_val - i) % 3 == 0) str_val += 'hundred '
      sk_val = 1
    }
    if ((x_val - i) % 3 == 1) {
      if (sk_val) str_val += th_val[(x_val - i - 1) / 3] + ' '
      sk_val = 0
    }
  }
  if (x_val != s.length) {
    var y_val = s.length
    str_val += 'point '
    for (i = x_val + 1; i < y_val; i++) str_val += dg_val[n_val[i]] + ' '
  }
  return str_val
    .replace(/\s+/g, ' ')
    .toUpperCase()
    .trim()
}
