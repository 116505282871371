import { GreenIdVerificationStatus, IdentificationIndividual } from '~/types'
export const isMissingIndividualInfo = (individual: IdentificationIndividual) => {
  if (!individual.firstName) return true
  if (!individual.surname) return true
  if (!individual.emailAddress) return true
  if (!individual.title) return true
  if (!individual.phoneMobile) return true
  if (individual.isForeignResident == null) return true
  if (individual.isForeignResident == false) return false
  // this condition is part of the individual requirements on the server
  if (
    ((individual.isDirectorOrSecretary !== null && individual.isDirectorOrSecretary) ||
      (individual.isBeneficialOwner !== null && individual.isBeneficialOwner)) &&
    (individual.greenIdVerificationStatus == GreenIdVerificationStatus.IN_PROGRESS ||
      individual.greenIdVerificationStatus == null) &&
    (!individual.isForeignResident !== null || !individual.isForeignResident)
  )
    return true
  if (individual.isForeignResident == true && !individual.taxForeignResidentCountry) return true
  if (individual.isForeignResident == true && individual.taxForeignResidentCountry) {
    if (!individual.taxpayerIdentificationNumber && !individual.taxReasonNoTIN) {
      return true
    }
    if (!individual.taxpayerIdentificationNumber && individual.taxReasonNoTIN) {
      return false
    }
    if (individual.taxpayerIdentificationNumber && !individual.taxReasonNoTIN) {
      return false
    }
  }

  return false
}
