import { GreenIdVerificationStatus, GreenIdWatchlistResult } from '~/types/graphql'

export const isGreenIdIncomplete = (greenIdVerificationStatus: GreenIdVerificationStatus | undefined | null) => {
  return greenIdVerificationStatus == GreenIdVerificationStatus.IN_PROGRESS || !greenIdVerificationStatus
}

export const isGreenIdRequiresAdmin = (greenIdVerificationStatus: GreenIdVerificationStatus | undefined | null) => {
  return (
    greenIdVerificationStatus == GreenIdVerificationStatus.PENDING ||
    greenIdVerificationStatus == GreenIdVerificationStatus.LOCKED_OUT ||
    greenIdVerificationStatus == GreenIdVerificationStatus.MANUAL
  )
}

export const isGreenIdVerified = (greenIdVerificationStatus: GreenIdVerificationStatus | undefined | null) => {
  return (
    greenIdVerificationStatus == GreenIdVerificationStatus.VERIFIED ||
    greenIdVerificationStatus == GreenIdVerificationStatus.VERIFIED_ADMIN ||
    greenIdVerificationStatus == GreenIdVerificationStatus.VERIFIED_WITH_CHANGES
  )
}

export const watchListIconAndClass = (flag: GreenIdWatchlistResult) => {
  const matchStatuses = {
    success: { icon: 'check-circle', class: 'has-text-success' },
    warning: { icon: 'alert-circle', class: 'has-text-warning' },
    error: { icon: 'x-circle', class: 'has-text-danger' },
    newWarning: { icon: 'alert-circle', class: '' }
  }
  if (flag === GreenIdWatchlistResult.NOT_FOUND_ON_LIST || flag === GreenIdWatchlistResult.MATCH_FALSE_POSITIVE) {
    return matchStatuses.success
  } else if (flag === GreenIdWatchlistResult.FOUND_ON_LIST || flag === GreenIdWatchlistResult.MATCH_CONFIRMED) {
    return matchStatuses.error
  } else if (flag === GreenIdWatchlistResult.MATCH_REVIEW_REQUIRED) return matchStatuses.warning
  else return matchStatuses.newWarning
}
