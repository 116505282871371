interface AppConfig {
  cognito: {
    userPoolId: string
    userPoolWebClientId: string
    region: string
    oauth?: {
      domain: string
      scope: string[]
      redirectSignIn: string
      redirectSignOut: string
      responseType: string
    }
  }
  api: {
    graphql_endpoint: string
  }
  environment: {
    name: string
    referralUrl: string
  }
  google: {
    apiKey: string
    analyticsId: string
    gtmId: string
    gtmAuth: string
    gtmIsDebugMode: boolean
    gtmIsEnabled: boolean
  }
  intercom: {
    appId: string
  }
  mixPanel: {
    enabled: boolean
    projectToken: string
  }
  greenId: {
    accountId: string
    isTestMode: boolean
    apiCode: string
  }
  reCaptcha: {
    siteKey: string
    enabled: boolean
  }
  stripe: {
    publishableKey: string
    stripeAccount: string
    apiVersion: string
    locale: string
  }
  featureFlags: {
    enableBankGuaranteeSwitch: boolean
    enableInvitePartiesToDraft: boolean
  }
}

const getConfig = <T>(path: string): Promise<T> => {
  return fetch(path).then(response => response.json())
}

export { AppConfig, getConfig }
