export const printer = (htmlToPrint: string) => {
  const WinPrint = window.open()
  WinPrint?.document.write(htmlToPrint)
  WinPrint?.document.close()
  // sometimes the SVG's don't render in time, so wait a frame
  requestAnimationFrame(() => {
    WinPrint?.focus()
    WinPrint?.print()
    WinPrint?.close()
  })
}
