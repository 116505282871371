import gql from 'graphql-tag'
import {
  DOCUMENT_QUERY_SHARED_FRAGMENT,
  DRAFT_QUERY_SHARED_FRAGMENT,
  REQUIRED_DOCUMENTS_QUERY_SHARED_FRAGMENT
} from './queries'

export const ORGANISATION_DOCUMENT_CHANGED_SUBSCRIPTION = gql`
  subscription OrganisationDocumentsChanged($organisationId: ID!) {
    organisationDocumentsChanged(organisationId: $organisationId)
  }
`

export const DOCUMENT_UPDATED_QUERY = gql`
  subscription DocumentUpdated($documentId: ID!) {
    documentUpdated(documentId: $documentId) {
      ${DOCUMENT_QUERY_SHARED_FRAGMENT}
    }
  }
`

export const DRAFT_UPDATED_QUERY = gql`
  subscription DraftUpdated($draftId: ID!, $organisationId: ID!) {
    draftUpdated(draftId: $draftId, organisationId: $organisationId) {
      ${DRAFT_QUERY_SHARED_FRAGMENT}
    }
  }
`

/**
 * NOTES:
 *  - errors is string[] and will contain values if the file itself had issues (e.g. headers could not be matched)
 *  - items.errors is string[] and will contain values if a particular row had issues.
 *  - status will be COMPLETED or FAILED. COMPLETED can still have failed records! If COMPLETED, look at the recordsFailed property to see if any failed (and should have errors)
 */
export const IMPORT_JOB_COMPLETED = gql`
  subscription importJobCompleted($importJobId: ID!) {
    importJobCompleted(importJobId: $importJobId) {
      errors
      files {
        contentMd5Checksum
        contentType
        fileName
        id
        received
        uploadPresignedUrl
      }
      items {
        errors
        rowNumber
      }
      recordsFailed
      recordsProcessed
      status
    }
  }
`
export const REQUIRED_DOCUMENTS_UPDATED_QUERY = gql`
  subscription DraftUpdated($draftId: ID!, $organisationId: ID!) {
    draftUpdated(draftId: $draftId, organisationId: $organisationId) {
      ${REQUIRED_DOCUMENTS_QUERY_SHARED_FRAGMENT}
    }
  }
`
