<template>
  <ValidationObserver v-slot="{ handleSubmit }" slim>
    <form @submit.prevent="handleSubmit(submitTermsAcceptance)">
      <div class="modal-card" data-cy-tag="terms-acceptance">
        <header class="modal-card-head">
          <p class="modal-card-title">Terms of Use</p>
        </header>
        <section class="modal-card-body">
          <p>Please review and accept Assuro's Terms and Conditions and Privacy Policy to continue.</p>
          <br />
          <ValidationProvider name="otherTerms" slim>
            <BField data-cy-tag="termsAcceptance">
              <BCheckbox v-model="termsAcceptance">
                I agree to Assuro's
                <RouterLink :to="{ name: 'terms' }" target="_blank">Terms and Conditions</RouterLink>.
              </BCheckbox>
            </BField>
          </ValidationProvider>
          <ValidationProvider v-slot="{ errors }" rules="acceptTerms:@otherTerms" tag="div">
            <BField :type="{ 'is-danger': errors[0] }" :message="errors" data-cy-tag="privacyAcceptance">
              <BCheckbox v-model="privacyAcceptance">
                I agree to Assuro's
                <a href="https://assuro.com.au/privacy" target="_blank"> Privacy Policy </a> -
                <a href="https://assuro.com.au/complaintspolicy" target="_blank"> Complaints Policy </a>
              </BCheckbox>
            </BField>
          </ValidationProvider>
        </section>
        <footer class="modal-card-foot">
          <BButton type="is-primary" native-type="submit"> Accept and Continue </BButton>
        </footer>
        <BLoading :is-full-page="false" :active.sync="isLoading" />
      </div>
    </form>
  </ValidationObserver>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator'
import { v4 as uuid } from 'uuid'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

import { namespaceWrapper } from '@/utils'
import { FETCH_USER_STATE, USER_MOD, CLOSE_INIT_PROMPT } from '@/store'

import { profileTermsAccepted } from '@/graphql'

const user = namespaceWrapper(USER_MOD)

@Component({
  components: { ValidationProvider, ValidationObserver }
})
export default class TermsOfUseAcceptanceModal extends Vue {
  termsAcceptance: boolean = false
  privacyAcceptance: boolean = false
  isError = false
  isLoading = false

  submitTermsAcceptance() {
    this.isLoading = true
    this.isError = false
    profileTermsAccepted({
      clientMutationId: uuid()
    })
      .then(data => {
        this.$store.dispatch(user(FETCH_USER_STATE))
        this.close()
        this.$buefy.toast.open({
          message: 'Terms of Use accepted'
        })
      })
      .catch(() => (this.isError = true))
      .finally(() => (this.isLoading = false))
  }

  close() {
    this.$emit('close')
    this.$store.commit(user(CLOSE_INIT_PROMPT))
  }
}
</script>
<style lang="scss" scoped>
.modal-card-body {
  overflow: visible !important;
}
</style>
