import { Module, GetterTree, MutationTree, ActionTree } from 'vuex'
import { RootState } from '@/store'
import { SnackbarProgrammatic, ModalProgrammatic } from 'buefy'
import { apolloClient } from '@/graphql/client'
import { subscriptions } from '@/graphql'
import DocumentUploadResultsModal from '@/components/DocumentUploadResultsModal.vue'
import { BNoticeComponent } from 'buefy/types/components'

// Actions
export const IMPORT_JOB_UPLOAD = 'importJobUpload'
export const IMPORT_JOB_SUBSCRIBE = 'importJobSubscribe'

// Mutations
export const SET_IMPORT_JOB_ID = 'setImportJobId'
export const SET_IMPORT_RESULTS = 'setImportSubscription'
export const SET_IMPORT_SUBSCRIPTION = 'setImportSubscription'
export const SET_LOADING_SNACKBAR = 'setLoadingSnackbar'

// Getters
export const IMPORT_JOB_ID = 'importJob'
export const IMPORT_RESULTS = 'importResults'
export const IMPORT_SUBSCRIPTION = 'importSubscription'

interface ImportState {
  importJobId: number | null
  importResults: {} | null
  importSubscription: ZenObservable.Subscription | null
  loadingSnackbar: BNoticeComponent | null
}

const state: ImportState = {
  importJobId: null,
  importResults: null,
  importSubscription: null,
  loadingSnackbar: null
}

const actions: ActionTree<ImportState, RootState> = {
  [IMPORT_JOB_SUBSCRIBE]({ commit, dispatch }, jobId) {
    commit(SET_IMPORT_JOB_ID, jobId)
    const subscription = apolloClient()
      .subscribe({
        query: subscriptions.IMPORT_JOB_COMPLETED,
        variables: {
          importJobId: jobId
        }
      })
      .subscribe({
        next: ({ data: { importJobCompleted } }) => {
          const importSuccess = importJobCompleted.status === 'COMPLETE'
          commit(SET_IMPORT_RESULTS, importJobCompleted)
          state.loadingSnackbar?.close()
          commit(SET_LOADING_SNACKBAR, null)
          SnackbarProgrammatic.open({
            indefinite: true,
            queue: false,
            message: importSuccess
              ? `<div>
                        <div>
                          <span class="icon-check-circle has-text-success"></span>
                          <b> ${importJobCompleted.recordsProcessed}</b> records imported successfully
                        </div>
                        <div>
                          <span class="icon-alert-triangle has-text-warning"></span>
                          <b> ${importJobCompleted.recordsFailed}</b> records failed to import
                        </div>
                      </div>`
              : `<div><span class="icon-alert-triangle has-text-danger"></span> Error processing document, upload was unsuccessful</div>`,
            position: 'is-bottom',
            type: importSuccess ? 'is-success' : 'is-danger',
            actionText: 'View',
            onAction: () => {
              if (importJobCompleted) {
                ModalProgrammatic.open({
                  component: DocumentUploadResultsModal,
                  trapFocus: true,
                  hasModalCard: true,
                  props: {
                    importResult: importJobCompleted
                  }
                })
              }
            }
          })
          commit(SET_IMPORT_SUBSCRIPTION, null)
        },
        error: error => {
          SnackbarProgrammatic.open({
            duration: 5000,
            message: error.message,
            position: 'is-bottom',
            type: 'is-danger'
          })

          commit(SET_IMPORT_SUBSCRIPTION, null)
        }
      })
    commit(SET_IMPORT_SUBSCRIPTION, subscription)
  },
  [IMPORT_JOB_UPLOAD]({ commit }) {
    const loadingSnackbar = SnackbarProgrammatic.open({
      indefinite: true,
      queue: false,
      message: `CSV results processing.`,
      position: 'is-bottom',
      type: 'is-info'
    })
    commit(SET_LOADING_SNACKBAR, loadingSnackbar)
  }
}

const mutations: MutationTree<ImportState> = {
  [SET_IMPORT_JOB_ID]: (state, importJobId) => (state.importJobId = importJobId),
  [SET_IMPORT_RESULTS]: (state, importResults) => (state.importResults = importResults),
  [SET_IMPORT_SUBSCRIPTION]: (state, importSubscription) => (state.importSubscription = importSubscription),
  [SET_LOADING_SNACKBAR]: (state, loadingSnackbar) => (state.loadingSnackbar = loadingSnackbar)
}

const getters: GetterTree<ImportState, RootState> = {
  [IMPORT_JOB_ID]: ({ importJobId }) => importJobId,
  [IMPORT_RESULTS]: ({ importResults }) => importResults,
  [IMPORT_SUBSCRIPTION]: ({ importSubscription }) => importSubscription
}

const importModule: Module<ImportState, RootState> = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state
}

export default importModule
