import { supplierNameBbaUpperCase, supplierNameBnkUpperCase, supplierNameMacquarieUpperCase } from '../constants'

/**
 *
 * @param value 'string  | null | undefined' value
 * @returns boolean value -  "true or false"
 */
export const isSupplierMacquarie = (value: string  | null | undefined):boolean =>
   !!value?.toUpperCase()?.includes(supplierNameMacquarieUpperCase)

/**
 *
 * @param value 'string  | null | undefined' value
 * @returns boolean value -  "true or false"
 */
export const isSupplierBNK = (value: string  | null | undefined):boolean =>
  !!value?.toUpperCase()?.includes(supplierNameBnkUpperCase)

/**
 *
 * @param value 'string  | null | undefined' value
 * @returns boolean value -  "true or false"
 */
export const isSupplierBBA = (value: string  | null | undefined):boolean =>
  !!value?.toUpperCase()?.includes(supplierNameBbaUpperCase)

/**
 *
 * @param value 'string  | null | undefined' value
 * @returns string value -  "Supplier trade name"
 */
export const getSupplierTradeName=(value: string  | null | undefined): string=> {
  if (isSupplierBNK(value)) return 'BNK Banking Corporation Limited'
  if (isSupplierMacquarie(value)) return 'Macquarie Bank Private Limited'
  if (isSupplierBBA(value)) return 'Beyond Bank Australia Limited'
  else return value || 'The Bank'
}
