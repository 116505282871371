import { CURRENCY_FORMAT_OPTIONS } from '@/constants'
import {
  AmendDocumentCreatePartyInput,
  AmendDocumentDeletePartyInput,
  AmendDocumentInput,
  AmendDocumentUpdatePartyInput,
  Document,
  DocumentRetentionType,
  Party,
  PartyVariant,
  SecurityType
} from '@/graphql/types'
import { RootState } from '@/store'
import { toUTCDate } from '@/utils'
import { v4 as uuid } from 'uuid'
import { parse } from 'vue-currency-input'
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'

// Actions

// Mutations
export const SET_DOCUMENT = 'setDocument'
export const UPDATE_DOCUMENT = 'updateDocument'
export const DELETE_PARTY = 'deleteParty'
export const CREATE_PARTY = 'createParty'
export const UPDATE_PARTY = 'updateParty'

// Getters
export const DOCUMENT = 'document'
export const PARTY = 'party'
export const PARTIES_BY_TYPE = 'partiesByType'
export const AMENDMENTS = 'amendments'

interface PartyState extends Party {
  [index: string]: any
  isDeleted?: boolean
  isNew?: boolean
}

interface DocumentState {
  [index: string]: any
  id: string
  amount: string
  agreementContent: string | null
  referenceNumber: string | null
  guaranteeNumber: string | null
  expiresAt: Date | null
  establishedAt: Date | null
  bankName: string | null
  retentionType: DocumentRetentionType | null
  securityType: SecurityType | null
  parties: PartyState[]
  originalDocument: Document | null
}

const state: DocumentState = {
  id: '',
  amount: '',
  agreementContent: null,
  referenceNumber: null,
  guaranteeNumber: null,
  expiresAt: null,
  establishedAt: null,
  bankName: null,
  retentionType: null,
  securityType: null,
  parties: [],
  originalDocument: null
}

const actions: ActionTree<DocumentState, RootState> = {}

const mutations: MutationTree<DocumentState> = {
  [SET_DOCUMENT](state, document: Document) {
    state.amount = '' + document.amount
    state.expiresAt = document.expiresAt ? new Date(document.expiresAt) : null
    state.establishedAt = document.establishedAt ? new Date(document.establishedAt) : null
    state.referenceNumber = document.referenceNumber || null
    state.agreementContent = document.agreementContent || null
    state.guaranteeNumber = document.guaranteeNumber || null
    state.bankName = document.bank ? document.bank.name : null
    state.retentionType = document.retentionType || null
    state.securityType = document.securityType || null
    state.parties = document.parties.map(p => ({ ...p, isNew: false, isDeleted: false }))
    state.id = document.id
    state.originalDocument = document
  },
  [UPDATE_DOCUMENT](state, changes: any) {
    Object.entries(changes).forEach(change => {
      state[change[0]] = change[1]
    })
  },
  [DELETE_PARTY](state, partyId: any) {
    const partyToRemove = state.parties.find(p => p.id == partyId)
    if (!partyToRemove) return

    if (partyToRemove.isNew) {
      state.parties = state.parties.filter(p => p.id != partyId)
    } else {
      partyToRemove.isDeleted = true
    }
  },
  [CREATE_PARTY](state, partyType: PartyVariant) {
    const parties = [...state.parties]
    parties.push({
      id: uuid(),
      name: '',
      partyVariant: partyType,
      isNew: true,
      abn: '',
      address: '',
      notes: ''
    })
    state.parties = parties
  },
  [UPDATE_PARTY](state, { partyId, changes }: { partyId: string; changes: any }) {
    const partyToUpdate = state.parties.find(p => p.id == partyId)
    if (!partyToUpdate) return

    Object.entries(changes).forEach(change => {
      partyToUpdate![change[0]] = change[1]
    })
  }
}

const getters: GetterTree<DocumentState, RootState> = {
  [DOCUMENT]: () => state,
  [PARTY]:
    ({ parties }) =>
    (partyId: string) =>
      parties.find(p => p.id == partyId),
  [PARTIES_BY_TYPE]: ({ parties }) =>
    [PartyVariant.ISSUER, PartyVariant.BENEFICIARY].map(pt => [
      pt,
      parties.filter(p => p.partyVariant === pt && !p.isDeleted)
    ]),
  [AMENDMENTS]: (): AmendDocumentInput | null => {
    if (!state.originalDocument) return null

    const amendments: AmendDocumentInput = {
      clientMutationId: uuid(),
      id: state.id
    }

    if (
      state.agreementContent !== state.originalDocument.agreementContent &&
      (state.agreementContent || state.originalDocument.agreementContent)
    ) {
      amendments.agreementContent = state.agreementContent!
    }

    if (
      state.referenceNumber !== state.originalDocument.referenceNumber &&
      (state.referenceNumber || state.originalDocument.referenceNumber)
    ) {
      amendments.referenceNumber = state.referenceNumber!
    }

    if (
      state.retentionType !== state.originalDocument.retentionType &&
      (state.retentionType || state.originalDocument.retentionType)
    ) {
      amendments.retentionType = state.retentionType!
    }

    if (
      state.securityType !== state.originalDocument.securityType &&
      (state.securityType || state.originalDocument.securityType)
    ) {
      amendments.securityType = state.securityType!
    }

    if (
      state.guaranteeNumber !== state.originalDocument.guaranteeNumber &&
      (state.guaranteeNumber || state.originalDocument.guaranteeNumber)
    ) {
      amendments.guaranteeNumber = state.guaranteeNumber!
    }

    if (state.bankName !== (state.originalDocument.bank ? state.originalDocument.bank.name : null)) {
      amendments.bankName = state.bankName!
    }

    const newAmount = parse(state.amount!, CURRENCY_FORMAT_OPTIONS)
    if (newAmount !== state.originalDocument.amount) {
      amendments.amount = newAmount
    }

    const newExpiresAt = state.expiresAt ? toUTCDate(state.expiresAt) : null
    if (newExpiresAt !== state.originalDocument.expiresAt) {
      amendments.expiresAt = newExpiresAt
    }

    const newEstablishedAt = state.establishedAt ? toUTCDate(state.establishedAt) : null
    if (newEstablishedAt !== state.originalDocument.establishedAt) {
      amendments.establishedAt = newEstablishedAt
    }

    const createdParties = state.parties
      .filter(p => p.isNew)
      .map(
        (p): AmendDocumentCreatePartyInput => ({
          abn: p.abn || undefined,
          address: p.address || undefined,
          name: p.name,
          notes: p.notes || undefined,
          partyVariant: p.partyVariant!
        })
      )
    if (createdParties.length) {
      amendments.partyCreates = createdParties
    }

    const deletedParties = state.parties
      .filter(p => p.isDeleted)
      .map(
        (p): AmendDocumentDeletePartyInput => ({
          id: p.id
        })
      )
    if (deletedParties.length) {
      amendments.partyDeletes = deletedParties
    }

    const updatedParties = state.parties
      .filter(p => !p.isDeleted && !p.isNew)
      .map((p): AmendDocumentUpdatePartyInput | null => {
        const existingParty = state.originalDocument!.parties.find((op: Party) => op.id == p.id)

        // party not found
        if (!existingParty) return null

        // party has no updates
        if (
          existingParty.abn === p.abn &&
          existingParty.address === p.address &&
          existingParty.name === p.name &&
          existingParty.notes === p.notes
        )
          return null

        return {
          id: p.id,
          abn: existingParty.abn !== p.abn ? p.abn : undefined,
          address: existingParty.address !== p.address ? p.address : undefined,
          name: existingParty.name !== p.name ? p.name : '',
          notes: existingParty.notes !== p.notes ? p.notes : undefined
        }
      })
      .filter(p => p != null)
      .map(p => p!)

    if (updatedParties.length) {
      amendments.partyUpdates = updatedParties
    }

    if (Object.entries(amendments).length == 2) {
      return null
    }

    return amendments
  }
}

const user: Module<DocumentState, RootState> = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state
}

export default user
