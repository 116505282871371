import { FetchFacilityFilter } from '@/graphql/types'
import { apolloClient, resetStore } from './client'
import * as mutations from './mutations'
import * as queries from './queries'
import * as subscriptions from './subscriptions'
import {
  AcceptInvitationInput,
  ChangeMemberRoleInput,
  CreateDocumentInput,
  CreateDraftInput,
  CreateImportJobInput,
  CreateImportJobPayload,
  CreateOrganisationInput,
  ProfileTermsAcceptedInput,
  QueryToDocumentsArgs,
  RemoveInvitationInput,
  RemoveMemberInput,
  UpdateNotifiedAtInput,
  UpdateProfileInput
} from './types'

export const createOrganisation = async (input: CreateOrganisationInput) =>
  await apolloClient().mutate({
    mutation: mutations.CREATE_ORGANISATION_MUTATION,
    variables: { input }
  })

export const fetchOrganisation = async (organisationId: string) =>
  await apolloClient()
    .query({
      query: queries.ORGANISATION_QUERY,
      variables: { organisationId }
    })
    .then(({ data }) => {
      const organisation = data.organisations.nodes[0]
      return organisation
    })

export const fetchOrganisationWithMetadata = async (organisationId: string) =>
  await apolloClient().query({
    query: queries.ORGANISATION_AND_METADATA_QUERY,
    variables: { organisationId }
  })

export const fetchFacilities = async (organisationId: string, otherVariables: any = {}, filter: string = '') =>
  await apolloClient()
    .query({
      query: queries.FACILITIES_QUERY,
      variables: {
        ...otherVariables,
        organisationId: organisationId
      }
    })
    .then(({ data }) => {
      const facilities = data.facilities
      if (filter === FetchFacilityFilter.CAN_ATTACH_TO_DOCUMENTS) {
        facilities.nodes = facilities.nodes.filter(({ capabilities }: any) =>
          capabilities.includes('CAN_ATTACH_TO_DOCUMENTS')
        )
        facilities.totalCount = facilities.nodes.length
      }
      if (filter === FetchFacilityFilter.CAN_RAISE_DRAFTS) {
        facilities.nodes = facilities.nodes.filter(({ capabilities }: any) => capabilities.includes('CAN_RAISE_DRAFTS'))
        facilities.totalCount = facilities.nodes.length
      }
      return facilities
    })
    .catch(() => [])

export const fetchFacilityApplications = async (organisationId: string, otherVariables: any = {}) =>
  await apolloClient()
    .query({
      query: queries.FACILITY_APPLICATIONS_LIST_QUERY,
      variables: {
        ...otherVariables,
        organisationId: organisationId
      }
    })
    .then(({ data }) => {
      return data.facilityApplications
    })

export const fetchOrganisationMetadata = async (organisationId: string) =>
  await apolloClient()
    .query({
      query: queries.ORGANISATION_METADATA_QUERY,
      variables: { organisationId }
    })
    .then(({ data: { organisations } }) => {
      return organisations.nodes[0]
    })

export const fetchDocuments = async (input: QueryToDocumentsArgs) =>
  await apolloClient().query({
    query: queries.DOCUMENTS_QUERY,
    variables: { ...input }
  })

export const fetchDocument = async (documentId: string) =>
  await apolloClient().query({
    query: queries.DOCUMENT_QUERY,
    variables: { documentId }
  })

export const fetchUserState = async () =>
  await apolloClient().query({
    query: queries.USER_STATE_QUERY
  })

export const fetchUserGatewayFeatures = async () =>
  await apolloClient().query({
    query: queries.GATEWAY_FEATURES_QUERY
  })

export const updateProfile = async (input: UpdateProfileInput) =>
  await apolloClient().mutate({
    mutation: mutations.UPDATE_PROFILE_MUTATION,
    variables: { input }
  })

export const updateNotifiedAt = async (input: UpdateNotifiedAtInput) =>
  await apolloClient().mutate({
    mutation: mutations.UPDATE_NOTIFIED_AT_MUTATION,
    variables: { input }
  })

export const profileTermsAccepted = async (input: ProfileTermsAcceptedInput) =>
  await apolloClient().mutate({
    mutation: mutations.PROFILE_TERMS_ACCEPTED_MUTATION,
    variables: { input }
  })

export const changeUserRole = async (input: ChangeMemberRoleInput) =>
  await apolloClient().mutate({
    mutation: mutations.CHANGE_MEMBER_ROLE_MUTATION,
    variables: { input }
  })

export const removeMember = async (input: RemoveMemberInput) =>
  await apolloClient().mutate({
    mutation: mutations.REMOVE_MEMBER_MUTATION,
    variables: { input }
  })

export const removeInvitation = async (input: RemoveInvitationInput) =>
  await apolloClient().mutate({
    mutation: mutations.REMOVE_INVITATION_MUTATION,
    variables: { input }
  })

export const createDocument = async (input: CreateDocumentInput) =>
  await apolloClient().mutate({
    mutation: mutations.CREATE_DOCUMENT_MUTATION,
    variables: { input }
  })

export const createDraft = async (input: CreateDraftInput) =>
  await apolloClient().mutate({
    mutation: mutations.CREATE_DRAFT_MUTATION,
    variables: { input }
  })

export const createImportJob = async (input: CreateImportJobInput) =>
  await apolloClient().mutate<CreateImportJobPayload, { input: CreateImportJobInput }>({
    mutation: mutations.CREATE_IMPORT_JOB_MUTATION,
    variables: { input }
  })

export const acceptInvitation = async (input: AcceptInvitationInput) =>
  await apolloClient().mutate({
    mutation: mutations.ACCEPT_INVITATION_MUTATION,
    variables: { input }
  })

export { mutations, queries, resetStore, subscriptions }
