import { BOND_PROVIDERS, DOCUMENT_VARIANT_OPTIONS, FINANCIAL_INSTITUTIONS } from '@/constants'
import { DocumentStatus, DocumentVariant, PartyVariant, RecurringFeeType } from '@/graphql/types'

export const guaranteeNumberLabel = (documentVariant: DocumentVariant) => {
  return [DOCUMENT_VARIANT_OPTIONS.get(documentVariant), 'number'].join(' ') || ''
}

export const bankNameLabel = (documentVariant: DocumentVariant) => {
  if (documentVariant == DocumentVariant.SURETY_BOND) return 'Insurance company / Underwriter'
  return 'Bank name'
}

export const partyTooltip = () => {
  return `“Issuer” is often referred to as the “Customer” on a bank guarantee or surety bond. “Beneficiary” may also be referred to as the “Favouree”.
    “Other” is any party that is not an Issuer/Customer or Beneficiary/Favouree on the document.
    You may be “Other” if you are a property agent or property manager, or if you are a builder or quantity surveyor.`
}

export const projectAddressTooltip = () => {
  return `“Contract Name/Project Address” refers to the contract name or key address that the bank guarantee, surety bond or cash security relates to.
    eg - In construction this could relate to the contract name or the address of the project.
    In the property industry, this would likely refer to the address of the property of the lease.`
}

export const guaranteeNumberTooltip = (documentVariant: DocumentVariant) => {
  return `“${guaranteeNumberLabel(documentVariant)}” is usually displayed on the physical document.`
}

export const referenceNumberTooltip = () => {
  return `“Reference number” is usually displayed on the physical document.`
}

export const expectedReturnDateTooltip = () => {
  return `“Expected return date” is the date the document is expected to be returned.`
}

export const bgExpectedReturnDateTooltip = () => {
  return `The expected return date is the date on which you expect to receive the bank guarantee back. The expected return date will not feature on the finalised bank guarantee but we collect the date as it links with our tools and reporting features.`
}

export const bankNameOptions = (documentVariant: DocumentVariant): string[] => {
  switch (documentVariant) {
    case DocumentVariant.BANK_GUARANTEE:
      return FINANCIAL_INSTITUTIONS
    case DocumentVariant.SURETY_BOND:
      return BOND_PROVIDERS
    default:
      return [...FINANCIAL_INSTITUTIONS, ...BOND_PROVIDERS]
  }
}

export const filteredBankNameOptions = (documentVariant: DocumentVariant, search: string): string[] => {
  return bankNameOptions(documentVariant).filter((option: string) => {
    return option.toString().toLowerCase().indexOf(search.toLowerCase()) >= 0
  })
}

export const documentVariantLabel = (documentVariant: DocumentVariant) => {
  switch (documentVariant) {
    case DocumentVariant.BANK_GUARANTEE:
      return 'Bank Guarantee'
    case DocumentVariant.SURETY_BOND:
      return 'Surety Bond'
    case DocumentVariant.CASH:
      return 'Cash Security'
    case DocumentVariant.LEASE_BOND:
      return 'Lease Bond'
    default:
      return 'Document'
  }
}

export const partyVariantLabel = (variant: PartyVariant, documentVariant: DocumentVariant) => {
  if (variant === PartyVariant.ISSUER) {
    if (documentVariant == DocumentVariant.CASH) return 'Sub Contractor'
    return 'Issuer'
  } else if (variant === PartyVariant.BENEFICIARY) {
    if (documentVariant == DocumentVariant.CASH) return 'Head Contractor'
    return 'Beneficiary'
  } else {
    return 'Other'
  }
}

export const recurringFeeTypeLabel = (fee: RecurringFeeType) => {
  if (fee === RecurringFeeType.ANNUAL_PERCENTAGE) {
    return 'Annual Percentage'
  }
  if (fee === RecurringFeeType.FIXED_FEE) {
    return 'Fixed Fee'
  }
  if (fee === RecurringFeeType.LIFETIME_FEE) {
    return 'Lifetime Fee'
  }
}

export const establishedAtLabel = (documentStatus: DocumentStatus) => {
  switch (documentStatus) {
    case DocumentStatus.PROJECTED:
      return 'Proposed Establishment Date'
    default:
      return 'Date Established'
  }
}

export const recurringFeeLabel = (months: number) => {
  const monthLabels: { [index: number]: string } = {
    1: 'Monthly',
    3: 'Quarterly',
    6: 'Biannual',
    12: 'Annual',
    24: '2 Years',
    36: '3 Years',
    0: 'End of Term'
  }
  return monthLabels[months] || `${months} Months`
}

export const setUpFeeLabel = () =>
  `“Set Up Fee” is the total of any fees that were payable when the Document was set up, e.g. an Establishment Fee.`
